import React, {ReactElement, useState} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import OverviewChart from './components/OverviewChart'
import classNames from 'classnames'
import OverviewProfitValueSummary from './components/OverviewProfitValueSummary'
import OverviewRefundProtectValueSummary from './components/OverviewRefundProtectValueSummary'
import OverviewBookingValueSummary from './components/OverviewBookingValueSummary'
import OverviewRefundProtectVolumeSummary from './components/OverviewRefundProtectVolumeSummary'
import OverviewBookingVolumeSummary from './components/OverviewBookingVolumeSummary'
import OverviewConversionRateSummary from './components/OverviewConversionRateSummary'
import OverviewPriceToCustomerSummary from './components/OverviewPriceToCustomerSummary'
import OverviewRevenuePerTransactionSummary from './components/OverviewRevenuePerTransactionSummary'
import OverviewRevenuePerCurrencySummary from './components/OverviewRevenuePerCurrencySummary'
import DashboardSupport from './components/DashboardSupport'
import RefundTimeSummary from './components/RefundTimeSummary'
import RefundAutoApprovalSummary from './components/RefundAutoApprovalSummary'
import RefundEmailVolumeSummary from './components/RefundEmailVolumeSummary'
import RefundTrustPilotScoreSummary from './components/RefundTrustPilotScoreSummary'
import RefundTrustPilotReviews from './components/RefundTrustPilotReviews'
import useExportOverviewData from './hooks/useExportOverviewData'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {exportDataState, overviewTitleState} from '../../state/pulse'
import MinimizeIcon from './components/Common/Images/MinimizeIcon'
import MaximizeIcon from './components/Common/Images/MaximizeIcon'

const PulseOverviewView = () => {
	const [showVolumes, setShowVolumes] = useState(false)
	const exportData = useExportOverviewData()
	const overviewTitle = useRecoilValue(overviewTitleState)
	const setExportDataState = useSetRecoilState(exportDataState)
	setExportDataState(exportData)

	const toggleShowVolumes = () => {
		setShowVolumes(!showVolumes)
	}

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl mb-4 px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<h2 className="
						mb-4
						font-semibold text-lg uppercase"
					>
						{overviewTitle}
					</h2>
					<ViewRow>
						<>
							<ViewItem>
								<OverviewProfitValueSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewRefundProtectValueSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewBookingValueSummary/>
							</ViewItem>
						</>
					</ViewRow>
					<div
						className="
							-bottom-3 hidden min-w-36 mx-4 px-2 py-1 right-10
							bg-white
							text-xs uppercase
							border border-pulse-icon-gray rounded-full
							cursor-pointer
							md:absolute md:block md:mx-0"
						onClick={toggleShowVolumes}
					>
						{showVolumes && (
							<>
								<MinimizeIcon/>
								<span className="ml-1">Hide Volumes</span>
							</>
						)}
						{!showVolumes && (
							<>
								<MaximizeIcon/>
								<span className="align-middle ml-1">Show Volumes</span>
							</>
						)}
					</div>
				</section>
				<section className="w-full max-w-6xl px-4 lg:mx-auto lg:px-10">
					<ViewRow className={classNames('overflow-hidden',
						'duration-200 ease-in-out transition-all',
						'md:max-h-0', {
							'md:max-h-screen': showVolumes
						})}
					>
						<>
							<div className="flex-1"></div>
							<ViewItem>
								<OverviewRefundProtectVolumeSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewBookingVolumeSummary/>
							</ViewItem>
						</>
					</ViewRow>
				</section>
				<section className="
					max-w-6xl mt-4 px-4 w-full
					lg:mx-auto lg:px-10"
				>
					<OverviewChart/>
				</section>
				<section className="
					max-w-6xl mt-4 pb-8 px-4 w-full
					lg:mx-auto lg:px-10"
				>
					<ViewRow>
						<>
							<ViewItem>
								<OverviewConversionRateSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewPriceToCustomerSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewRevenuePerTransactionSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewRevenuePerCurrencySummary/>
							</ViewItem>
						</>
					</ViewRow>
				</section>
				<section className="bg-pulse-gray">
					<div className="
						max-w-6xl px-4 py-8 w-full
						bg-pulse-gray
						lg:mx-auto lg:px-10"
					>
						<h2 className="
							mb-2
							font-semibold text-2xl"
						>
							Customer Experience
						</h2>
						<ViewRow>
							<>
								<ViewItem>
									<RefundTimeSummary/>
								</ViewItem>
								<ViewItem>
									<RefundAutoApprovalSummary/>
								</ViewItem>
								<ViewItem>
									<RefundEmailVolumeSummary/>
								</ViewItem>
								<ViewItem>
									<RefundTrustPilotScoreSummary/>
								</ViewItem>
							</>
						</ViewRow>
						<div className="mt-12">
							<RefundTrustPilotReviews/>
						</div>
					</div>
				</section>
				<section className="
					max-w-6xl px-4 py-8 w-full
					lg:mx-auto lg:px-10"
				>
					<DashboardSupport/>
				</section>
			</>
		</DashboardPageLayout>
	)
}

const ViewRow = (props: { children: ReactElement, className?: string }) => (
	<div className={classNames(
		'md:flex md:space-x-4',
		props.className)}
	>
		{props.children}
	</div>
)

const ViewItem = (props: { children: ReactElement }) => (
	<div className="flex-1 mb-4 md:mb-0">
		{props.children}
	</div>
)

export default PulseOverviewView
