import React, {useCallback, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {selectedMemberIdState} from '../../../../../state/pulse-filters'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import {membersState} from '../../../../../state/pulse'
import _ from 'underscore'

const MemberFilter = () => {
	const members = useRecoilValue(membersState)
	const [memberId, setMemberId] = useRecoilState(selectedMemberIdState)
	const selectedMember = members.filter(x => x.memberId === memberId)
	const [filter, setFilter] = useState('')
	const [tempFilter, setTempFilter] = useState('')

	const handleFilterChange = (event: any) => {
		setTempFilter(event.target.value)
		debounceFilter(event.target.value)
	}

	const debounceFilter = useCallback((searchValue: string) =>
			_.debounce((_: string) => {
				setFilter(searchValue)
			}, 1000)(searchValue),
		[setFilter]
	)

	return (<>
			<Popover className="w-full">
				<PopoverButton className="
					items-center inline-flex justify-center max-w-[400px] w-full
					outline-none"
				>
					<div className="
						flex mb-2 mt-4 pl-3 pr-2 py-[3px] w-full
						bg-neutral-200
						text-nowrap text-sm tracking-[1px] uppercase
						rounded-full
						cursor-pointer
						hover:bg-pulse-button-blue
						lg:mb-0"
					>
						{!memberId && (
							<span className="
								flex-1
								leading-5 text-left text-sm uppercase"
							>
								All Partners
							</span>
						)}
						{memberId && members && (
							<span className="
								flex-1
								leading-5 text-left text-sm uppercase"
							>
								{selectedMember.length > 0 && selectedMember[0].memberName}
							</span>
						)}
						<span className="
							align-middle ml-1
							leading-5
							material-symbols-outlined"
						>
							keyboard_arrow_down
						</span>
					</div>
				</PopoverButton>
				<PopoverPanel anchor="bottom start" className="z-20 text-xs">
					{({close}) => (
						<div className="
							flex flex-col m-[2px] mt-[5px] h-[400px] relative w-[var(--button-width)]
							bg-white
							border border-pulse-popup-border-gray rounded-lg"
						>
							<div className="inline-block mb-2 mt-3 w-full">
								<div className="mx-2 relative">
									<span className="
										absolute bottom-0 right-3 top-1
										text-2xl text-pulse-icon-gray
										material-symbols-outlined"
									>
										search
									</span>
									<input
										type="text"
										id="search"
										value={tempFilter}
										onChange={handleFilterChange}
										className="
											pl-4 pr-4 py-2 w-full
											bg-[#f9f9f9]
											text-sm text-nowrap uppercase
											border border-pulse-popup-border-gray outline-none rounded-full
											lg:mb-0"
									/>
								</div>
							</div>
							<div className="
								flex flex-col flex-1 min-w-44
								overflow-y-auto
								uppercase text-sm tracking-[1px]"
							>
								<div
									onClick={() => {
										setMemberId(null)
										close()
									}}
									className="
										px-6 py-1
										cursor-pointer
										hover:bg-pulse-button-blue"
								>
									All
								</div>
								{members
									.filter((member) => member.memberName
										.toLowerCase()
										.startsWith(filter.toLowerCase()))
									.map((member) => (
										<div
											key={member.memberId}
											onClick={() => {
												setMemberId(member.memberId)
												close()
											}}
											className="
												px-6 py-1
												cursor-pointer
												hover:bg-pulse-button-blue"
										>
											{member.memberName}
										</div>
								))}
						</div>
					</div>
					)}
				</PopoverPanel>
			</Popover>
		</>
	)
}

export default MemberFilter
