import React from 'react'
import PrimaryVolumeCard from './Common/PrimaryVolumeCard/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewBookingVolumeSummary = () => {
	const {getBookingVolumeSummary} = usePulseApi()
	const value = useApiValue(filterState, getBookingVolumeSummary, 0)

	return (
		<PrimaryVolumeCard
			value={value.data}
			header="Gross Booking Volume"
			tooltipText="The number of transactions including Refund Protect opted-in and non opted-in volumes."
			valueClassName="text-black"
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewBookingVolumeSummary
