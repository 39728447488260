import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {PulseTableDataModel} from '../../../types/pulse-data'
import {graphFilterColor, graphFilterName, graphFilterType} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'

export default function useGetRevenueTrendsChartData(recoilValue: { data: PulseTableDataModel; isLoading: boolean }) {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getOptions = () => {
		const options: ChartOptions<'line'> = {
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						padding: 30
					}
				},
				tooltip: {
					enabled: true,
					mode: 'x',
					callbacks: {
						label: (tooltip: TooltipItem<'line'>) => {
							if (tooltip.dataset.label === graphFilterName[graphFilterType.RevenuePerDollar]) {
								return `${tooltip.dataset.label}: ${formatCurrency(coreCurrency, tooltip.raw as any, 0, 2)}`
							}

							if (tooltip.dataset.label === graphFilterName[graphFilterType.RefundProtectValue] ||
								tooltip.dataset.label === graphFilterName[graphFilterType.YourRevenue] ||
								tooltip.dataset.label === graphFilterName[graphFilterType.GrossBookingValue]) {
								return `${tooltip.dataset.label}: ${formatCurrency(coreCurrency, tooltip.raw as any)}`
							}

							return `${tooltip.dataset.label}: ${tooltip.raw}`
						}
					}
				}
			}
		}

		return options
	}

	const getLabels = () => recoilValue.data.labels

	const getDatasets = () => {
		const datasets: ChartDataset<'line'>[] = []

		for (const key in recoilValue.data.chartData) {
			const value = recoilValue.data.chartData[parseInt(key)]
			const filterType: graphFilterType = parseInt(key)

			datasets.push({
				label: graphFilterName[filterType],
				data: value,
				fill: false,
				borderColor: graphFilterColor[filterType],
				backgroundColor: graphFilterColor[filterType],
				borderWidth: 1,
				pointRadius: 2,
				tension: 0
			})
		}

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions
	}
}
