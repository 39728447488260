import React from 'react'
import {Bar} from 'react-chartjs-2'
import {FixedHeightChartCard} from '../../../../components/Card/FixedHeightChartCard'
import {ChartHeightValue, tooltipList} from '../../../../utils/chart'
import {integrationFilterState} from '../../../../state/integration'
import {useApiValue} from '../../../../hooks/api'
import {useGetBarChartData} from '../../../../hooks/useGetChartDataSummary'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {defaultStatusBarChartData} from '../../../../types/integration'

export const VerticaStatusTimeChart = () => {
	const {getDaysInStatus} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getDaysInStatus, defaultStatusBarChartData)
	const {getChartData, barChartOptions} = useGetBarChartData(data.verticalData)

	return (
		<FixedHeightChartCard
			title={`Status distribution by vertical`}
			titleClass="card-title-info"
			height={ChartHeightValue['small']}
			tooltip={tooltipList.VerticalStatusTime}
		>
			<Bar
				data={getChartData()}
				options={barChartOptions}
				className="main-chart-max-height"
			/>
		</FixedHeightChartCard>
	)
}

export const RegionStatusTimeChart = () => {
	const {getDaysInStatus} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getDaysInStatus, defaultStatusBarChartData)
	const {getChartData, barChartOptions} = useGetBarChartData(data.regionData)

	return (
		<FixedHeightChartCard
			title={`Status distribution by region`}
			titleClass="card-title-info"
			height={ChartHeightValue['small']}
			tooltip={tooltipList.RegionStatusTime}
		>
			<Bar
				data={getChartData()}
				options={barChartOptions}
				className="main-chart-max-height"
			/>
		</FixedHeightChartCard>
	)
}
