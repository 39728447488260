import React from 'react'
import PrimaryValueCard from './Common/PrimaryValueCard/PrimaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewRefundProtectValueSummary = () => {
	const {getRefundProtectValueSummary, getRefundProtectRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getRefundProtectValueSummary, 0)
	const trendRate = useApiValue(filterState, getRefundProtectRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Refund Protect Value"
			tooltipText="The total value of transactions with Refund Protect opted-in."
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewRefundProtectValueSummary
