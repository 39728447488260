import React from 'react'
import PrimaryValueCard from './Common/PrimaryValueCard/PrimaryValueCard'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'

const OverviewProfitValueSummary = () => {
	const {getProfitValueSummary, getProfitRateTrend} = usePulseApi()
	const profitValue = useApiValue(filterState, getProfitValueSummary, 0)
	const trendRate = useApiValue(filterState, getProfitRateTrend, 0)

	return (
		<PrimaryValueCard
			value={profitValue.data}
			trendValue={trendRate.data}
			header="Your Revenue"
			tooltipText="Your Revenue share of the shared customer fees paid through Refund Protect."
			className="bg-pulse-highlight-blue"
			valueClassName="text-pulse-blue"
			isLoading={profitValue.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewProfitValueSummary
