import React, {useEffect, useState} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {integrationFilterState} from '../../../../state/integration'
import {IntegrationGuidesData} from '../../../../types/integration'
import {getColorPalette} from '../../../../utils/colors'
import {useApiValue} from '../../../../hooks/api'
import {useIntegrationApi} from '../../../../hooks/api/integration'

export const IntegrationGuidesChart = () => {
	const {getGuidesChartData} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getGuidesChartData, {} as IntegrationGuidesData)
	const [guidesData, setGuidesData] = useState<number[]>([])

	useEffect(() => {
		if (data) {
			setGuidesData([
				data.dynamicWidget,
				data.dynamicApi,
				data.dynamicHybrid,
				data.standardRpWidget
			])
		}
	}, [data])

	const chartData = {
		labels: [
			'Dynamic Widget',
			'Dynamic API',
			'Dynamic Hybrid',
			'Standard RP'
		],
		datasets: [{
			label: 'Guides Chart',
			data: guidesData,
			backgroundColor: getColorPalette(4),
			hoverOffset: 4
		}]
	}

	return (
		<ChartCard
			title="Guides distribution"
			titleClass="card-title-warning"
		>
			<Doughnut
				options={{maintainAspectRatio: false}}
				height={400}
				width={400}
				data={chartData}
			/>
		</ChartCard>
	)
}