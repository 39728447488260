import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import DashboardSupport from './components/DashboardSupport'
import {useSetRecoilState} from 'recoil'
import {exportDataState} from '../../state/pulse'
import CustomerTrendsGraph from './components/CustomerTrendsGraph'
import useExportCustomerTrendsMapData from './hooks/useExportCustomerTrendsMapData'

const PulseCustomerTrendsView = () => {
	const exportData = useExportCustomerTrendsMapData()
	const setExportDataState = useSetRecoilState(exportDataState)
	setExportDataState(exportData)

	return (
		<DashboardPageLayout>
			<section className="
				max-w-6xl px-4 w-full
				lg:mx-auto lg:px-10"
			>
				<div className="mb-8">
					<CustomerTrendsGraph/>
				</div>
				<div>
					<DashboardSupport/>
				</div>
			</section>
		</DashboardPageLayout>
	)
}

export default PulseCustomerTrendsView
