import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {dateFromState, dateMonthState, dateToState, selectedDateRangeState} from '../../../../../state/pulse-filters'
import {calendarDatesState} from '../../../../../state/pulse'

const MonthsFilter = () => {
	const date = useRecoilValue(selectedDateRangeState)
	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setCalendarDates = useSetRecoilState(calendarDatesState)
	const [dateMonth, setDateMonth] = useRecoilState(dateMonthState)

	const handleMonthClick = () => {
		const from = dateMonth.clone().startOf('month')
		const to = dateMonth.clone().endOf('month')

		setCalendarDates([from.toDate(), to.toDate()])
		setDateFrom(from)
		setDateTo(to)
	}

	const handlePreviousMonthClick = () => {
		if (date !== dateRange.Month) {
			handleMonthClick()
			return
		}

		const current = dateMonth.clone().add(-1, 'month')
		const from = current.clone().startOf('month')
		const to = current.clone().endOf('month')

		setCalendarDates([from.toDate(), to.toDate()])
		setDateFrom(current.startOf('month'))
		setDateTo(current.endOf('month'))
		setDateMonth(current)
	}

	const handleNextMonthClick = () => {
		if (date !== dateRange.Month) {
			handleMonthClick()
			return
		}

		const current = dateMonth.clone().add(1, 'month')
		const from = current.clone().startOf('month')
		const to = current.clone().endOf('month')

		setCalendarDates([from.toDate(), to.toDate()])
		setDateFrom(from)
		setDateTo(to)
		setDateMonth(current)
	}

	return (
		<div className={classNames('left-4 mb-2 mr-2 py-[2px] top-2',
			'cursor-pointer',
			'rounded-full border border-pulse-icon-gray',
			'text-sm text-nowrap tracking-[1px] uppercase',
			'hover:bg-pulse-button-blue',
			'lg:left-0 lg:mb-0 lg:relative lg:top-0', {
				'bg-pulse-button-blue': date === dateRange.Month,
				'bg-white': date !== dateRange.Month,
				'absolute': date === dateRange.Month
			})}
		>
			<span
				className="align-middle leading-5 px-3 material-symbols-outlined"
				onClick={handlePreviousMonthClick}
			>
				chevron_left
			</span>
			<span
				className="align-middle leading-5"
				onClick={handleMonthClick}
			>
				{dateMonth.format('MMMM YY')}
			</span>
			<span
				className="align-middle leading-5 px-3 material-symbols-outlined"
				onClick={handleNextMonthClick}
			>
				chevron_right
			</span>
		</div>
	)
}

export default MonthsFilter
