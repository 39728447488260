import {useEffect, useState} from 'react'
import {useCountUp} from 'react-countup'
import {useMemberApi} from '../../../hooks/api/member'
import {SummaryResult} from '../../../types/member'
import {useApiValue} from '../../../hooks/api'
import {memberFilterState} from '../../../state/memberFilterState'

const defaultSummaryData: SummaryResult = {
	protectedRate: 0,
	protectedValue: 0,
	totalConversionRate: 0,
	totalEventCount: 0,
	totalProtectionCost: 0,
	totalProtectionRate: 0,
	totalTransactionValue: 0,
	totalTransactionValueInsured: 0,
	totalTransactionVolume: 0,
	totalTransactionVolumeInsured: 0,
	unProtectedRate: 0,
	unProtectedValue: 0
}

export default function useGetSummaryValues() {
	const {getSummaryData} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getSummaryData, defaultSummaryData)
	const [totalTransactionValueInsuredState, setTotalTransactionValueInsuredState] = useState(0)
	const [totalTransactionVolumeInsuredState, setTotalTransactionVolumeInsuredState] = useState(0)
	const [totalEventCountState, setTotalEventCountState] = useState(0)
	const [totalProtectionRateState, setTotalProtectionRateState] = useState(0)
	const [totalProtectionCostState, setTotalProtectionCostState] = useState(0)
	const [totalConversionRateState, setTotalConversionRateState] = useState(0)

	const {
		countUp: totalTransactionValueInsured,
		update: updateTotalTransactionValueInsured
	} = useCountUp({end: totalTransactionValueInsuredState})

	const {
		countUp: totalTransactionVolumeInsured,
		update: updateTotalTransactionVolumeInsured
	} = useCountUp({end: totalTransactionVolumeInsuredState})

	const {
		countUp: totalEventCount,
		update: updateTotalEventCount
	} = useCountUp({end: totalEventCountState})

	const {
		countUp: totalProtectionRate,
		update: updateTotalProtectionRate
	} = useCountUp({end: totalProtectionRateState, decimals: 2})

	const {
		countUp: totalProtectionCost,
		update: updateTotalProtectionCost
	} = useCountUp({end: totalProtectionCostState})

	const {
		countUp: totalConversionRate,
		update: updateTotalConversionRate
	} = useCountUp({end: totalConversionRateState})

	useEffect(() => {
		if (data && !isLoading) {
			setTotalTransactionValueInsuredState(data.totalTransactionValueInsured)
			setTotalTransactionVolumeInsuredState(data.totalTransactionVolumeInsured)
			setTotalEventCountState(data.totalEventCount)
			setTotalProtectionRateState(data.protectedRate)
			setTotalProtectionCostState(data.protectedValue)
			setTotalConversionRateState(data.totalConversionRate)
		}

	}, [isLoading, data])

	useEffect(() => {
		updateTotalTransactionValueInsured(totalTransactionValueInsuredState)
	}, [totalTransactionValueInsuredState, updateTotalTransactionValueInsured])

	useEffect(() => {
		updateTotalTransactionVolumeInsured(totalTransactionVolumeInsuredState)
	}, [totalTransactionVolumeInsuredState, updateTotalTransactionVolumeInsured])

	useEffect(() => {
		updateTotalEventCount(totalEventCountState)
	}, [totalEventCountState, updateTotalEventCount])

	useEffect(() => {
		updateTotalProtectionRate(totalProtectionRateState)
	}, [totalProtectionRateState, updateTotalProtectionRate])

	useEffect(() => {
		updateTotalProtectionCost(totalProtectionCostState)
	}, [totalProtectionCostState, updateTotalProtectionCost])

	useEffect(() => {
		updateTotalConversionRate(totalConversionRateState)
	}, [totalConversionRateState, updateTotalConversionRate])

	return {
		totalTransactionValueInsured,
		totalTransactionVolumeInsured,
		totalEventCount,
		totalProtectionRate,
		totalProtectionCost,
		totalConversionRate
	}
}
