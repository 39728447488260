import React, {useCallback, useState} from 'react'
import {useResetRecoilState, useSetRecoilState} from 'recoil'
import {selectedTransactionsState, tablePageState, tableSearchState} from '../../../../../state/pulse'
import _ from 'underscore'

const TransactionSearch = () => {
	const [tempSearch, setTempSearch] = useState('')
	const setSearch = useSetRecoilState(tableSearchState)
	const resetPage = useResetRecoilState(tablePageState)
	const resetSelectedTransactions = useResetRecoilState(selectedTransactionsState)

	const handleSearchChange = (event: any) => {
		setTempSearch(event.target.value)
		debounceSearch(event.target.value)
	}

	const debounceSearch = useCallback((searchValue: string) =>
			_.debounce((val: string) => {
				resetPage()
				resetSelectedTransactions()
				setSearch(val)
			}, 1000)(searchValue),
		[resetPage, resetSelectedTransactions, setSearch]
	)

	return (
		<div className="inline-block relative">
      <span className="
        absolute bottom-0 right-2 top-0
        text-lg text-pulse-icon-gray
        material-symbols-outlined"
      >
	      search
			</span>
			<input
				type="text"
				id="search"
				value={tempSearch}
				onChange={handleSearchChange}
				className="
					mb-2 pl-2 pr-3 py-[2px]
					bg-white
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray outline-none rounded-full
					lg:mb-0"
				placeholder="SEARCH"
			/>
		</div>
	)
}

export default TransactionSearch
