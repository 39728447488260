import React from 'react'
import Logo from './Common/Images/Logo'

const DashboardFooter = () => {
	return (
		<footer className="
			max-w-6xl mb-20 mt-4 px-4 w-full
			bg-white
			lg:mx-auto lg:px-10"
		>
			<div className="
				p-2 pb-20
				border-gray-200 border-t
				lg:pt-5"
			>
				<Logo className="opacity-20"/>
			</div>
		</footer>
	)
}

export default DashboardFooter
