import React, {memo, useState, useEffect} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import {useRecoilValue} from 'recoil'
import DataTable from 'react-data-table-component'
import {
	commissionFilterState,
	commissionSummaryTableColumnsState,
	commissionTableColumnsState,
} from '../../../state/commission-data'
import {useCommissionRevenueToggles} from './CommissionRevenueToggles'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'

const EpCommissionTable = memo(() => {
	const [showPaging, setShowPaging] = useState<boolean>(false)
	const {selectedToggle, toggleComponent} = useCommissionRevenueToggles()
	const columns = useRecoilValue(commissionTableColumnsState(selectedToggle))
	const summaryColumns = useRecoilValue(commissionSummaryTableColumnsState(selectedToggle))
	const {getEpCommissionData} = useCommissionApi()
	const {data: tableData} = useApiValue(commissionFilterState, getEpCommissionData, {
		data: {},
		total: {}
	})

	useEffect(() => {
		setShowPaging(tableData.data.length > 10)
	}, [tableData])

	return (
		<TableCard
			title="Event Protect"
			titleClass="card-title-ep"
			showToggle={true}
			headerToggle={toggleComponent}
		>
			<>
				<DataTable
					data={[tableData.data]}
					columns={columns}
					theme="dark"
					pagination={showPaging}
				/>
				<DataTable
					columns={summaryColumns}
					data={[tableData.total]}
					theme="dark"
				/>
			</>
		</TableCard>

	)
})

export {EpCommissionTable}
