import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {coreCurrencyDropdownData} from "../../../../utils/data";

const newCurrencies = [
	{ id: '6', value: 'CNY' },
	{ id: '7', value: 'INR' },
	{ id: '8', value: 'JPY' },
	{ id: '9', value: 'KRW' },
	{ id: '10', value: 'MYR' },
	{ id: '11', value: 'PHP' },
	{ id: '12', value: 'RUB' }
]
	
const currencies = [...coreCurrencyDropdownData, ...newCurrencies]
	.map((x) => ({
		value: x.value,
		label: x.value
	}))

const CoreCurrencyDropdown = () => {
	return (
		<StatefulSelectFilterCard
			title="Core Currency"
			items={currencies}
			state={selectedCoreCurrencyStateMember}
			showDefault={false}
		/>
	)
}

export {CoreCurrencyDropdown}




