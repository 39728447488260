import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotValueCard from './Common/TrustPilotValueCard/TrustPilotValueCard'

const RefundTimeSummary = () => {
	const {getAverageRefundHandleTime} = usePulseApi()
	const value = useApiValue(filterState, getAverageRefundHandleTime, 0)

	return (
		<TrustPilotValueCard
			value={`${value.data}h`}
			header="Average Refund Time"
			tooltipText="The average time Protect Group takes to approve and initiate the payment.
				This does not include the time taken to process the payment."
			isLoading={value.isLoading}
		/>
	)
}

export default RefundTimeSummary
