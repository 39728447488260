import {usePulseApi} from '../../../hooks/api/pulse'
import {tableFilterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {dateFormat} from '../../../utils/date'
import {formatCurrency} from '../../../utils/currency'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'

export default function useExportTransactionData(): ExportData {
	const {
		getTransactionCSVData
	} = usePulseApi()
	const filter = useRecoilValue(tableFilterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getData = async (): Promise<any> => {
		const result = await getTransactionCSVData(filter)
		const data = []

		for (let i = 0; i < result.length; i++) {
			const row = result[i]

			data.push({
				'Transaction ID': row.transactionId,
				'Booking Reference': row.transactionReferenceId,
				'Customer Name': row.customerName,
				'Partner': row.member,
				'Region': row.region,
				'Booking Date': moment(row.transactionDate).format(dateFormat),
				'Event Date': moment(row.transactionEndDate).format(dateFormat),
				'Customer Fee': formatCurrency(coreCurrency, row.transactionConsumerValue, 0, 4),
				'Refundable Value': formatCurrency(coreCurrency, row.transactionValue, 0, 4),
				'Is Insured': row.isInsured ? 'Yes' : 'No',
				'Is Cancelled': row.isCancelled ? 'Yes' : 'No',
			})
		}

		return data
	}
	const getFileName = () => `Transactions-${moment().toISOString()}`
	const getHeaders = () => [
		'Transaction ID',
		'Booking Reference',
		'Customer Name',
		'Partner',
		'Region',
		'Booking Date',
		'Event Date',
		'Customer Fee',
		'Refundable Value',
		'Is Insured',
		'Is Cancelled',
	]

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
