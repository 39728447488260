import React, {ReactElement} from 'react'
import DashboardHeader from './DashboardHeader'
import DashboardFooter from './DashboardFooter'
import DashboardNavBar from './DashboardNavBar'
import DashboardToast from './DashboardToast'
import DashboardState from './DashboardState'

export interface DashboardPageLayoutProps {
	children: ReactElement;
}

const DashboardPageLayout = (props: DashboardPageLayoutProps) => {
	return (
		<>
			<DashboardState/>
			<section className="flex flex-col min-h-screen">
				<DashboardHeader/>
				{props.children}
				<DashboardFooter/>
				<DashboardNavBar/>
				<DashboardToast/>
			</section>
		</>
	)
}

export default DashboardPageLayout
