import React from 'react'
import PrimaryVolumeCard from './Common/PrimaryVolumeCard/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewRefundProtectVolumeSummary = () => {
	const {getRefundProtectVolumeSummary} = usePulseApi()
	const value = useApiValue(filterState, getRefundProtectVolumeSummary, 0)

	return (
		<PrimaryVolumeCard
			value={value.data}
			header="Refund Protect Volume"
			tooltipText="The number of transactions with Refund Protect opted-in."
			valueClassName="text-black"
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewRefundProtectVolumeSummary
