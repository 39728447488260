import React from 'react'

interface TrustPilotReviewCardProps {
	header: string,
	body: string,
	stars: number
}

const TrustPilotReviewCard = (props: TrustPilotReviewCardProps) => {
	const starCount = 5
	const starPercentage = (props.stars / starCount) * 100

	return (
		<div className="min-h-20 pt-5">
			<div className="h-[16px] mb-1 relative w-[92px]">
				<div className="
					absolute bottom-0 top-0 w-full
					bg-pulse-icon-gray"
				></div>
				<div
					className="
						absolute bottom-0 top-0
						bg-pulse-green"
					style={{'width': starPercentage + '%'}}
				></div>
				<div className="absolute">
					{[
						...Array(starCount),
					].map((_, index) => (
						<span
							key={`trustpilotstar-${index}`}
							className="
								align-top px-[2px] py-[2px]
								text-xs text-white
								border-x border-r-pulse-gray"
						>
							★
						</span>
					))}
				</div>
			</div>
			<div className="
				mb-2
				font-medium text-black text-xs"
			>
				{props.header}
			</div>
			<div className="
				mb-1
				text-black text-xs"
			>
				{props.body}
			</div>
		</div>
	)
}

export default TrustPilotReviewCard
