import React, {memo} from 'react'
import {FilterCard, FilterCardTitlePosition} from '../../../components/Card/FilterCard'
import {commissionFilterState} from '../../../state/commission-data'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'

interface CommissionRateProps {
	rate: any
}

const CommissionRate = (props: CommissionRateProps) => {
	const {rate} = props
	return (
		<div className="border border-1 border-dark p-3 rounded mr-4">
			<span>Year {rate.year}: {rate.rate}%</span>
		</div>
	)
}

const UserCommissionRates = memo(() => {
	const {getUserRates} = useCommissionApi()
	const {data} = useApiValue(commissionFilterState, getUserRates, [])

	if (!data.length) {
		return null
	}

	return (
		<FilterCard
			icon="icon-coins"
			title="Commission Scheme"
			titlePosition={FilterCardTitlePosition.left}
		>
			<div className="d-flex flex-row">
				{data.map(x => <CommissionRate key={x.year} rate={x}/>)}
			</div>
		</FilterCard>
	)
})

export default UserCommissionRates
