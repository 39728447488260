import React, {useEffect} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {filterState, membersState, salesRegionsState, userDetailState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'

const DashboardState = () => {
	const filter = useRecoilValue(filterState)
	const {getMembers, getSalesRegions, getUserDetail} = usePulseApi()
	const setUserDetailState = useSetRecoilState(userDetailState)
	const setMembersState = useSetRecoilState(membersState)
	const setSalesRegionsState = useSetRecoilState(salesRegionsState)

	useEffect(() => {
		getMembers(filter)
			.then(members => setMembersState(members))

		getUserDetail(filter)
			.then(userDetail => setUserDetailState(userDetail))

		getSalesRegions(filter)
			.then(salesRegions => setSalesRegionsState(salesRegions))
	}, [filter])

	return (<></>)
}

export default DashboardState
