import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'

interface PrimaryVolumeCardProps {
	header: string,
	tooltipText: string,
	value?: number,
	valueClassName: string,
	isLoading: boolean
}

const PrimaryVolumeCard = (props: PrimaryVolumeCardProps) => {
	const id = uuidv4()

	return (
		<div className="
			flex h-full min-h-24 px-7 py-3 relative
			rounded-lg"
		>
			<LoadingSpinner
				isLoading={props.isLoading}
				className="h-full w-full">
				<>
					<div className="flex-none mr-2 w-12">
					</div>
					<div>
						<div className="
							mb-1
							text-sm text-pulse-icon-gray"
						>
							{props.header}
						</div>
						<div className={`
							mb-1 text-2xl
							${props.valueClassName}`}
						>
							{props.value}
						</div>
						<Tooltip
							id={`${id}-popup`}
							tooltipTitle={props.header}
							tooltipText={props.tooltipText}
						/>
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default PrimaryVolumeCard
