//Formatting money and symbol considering to passed currency code.
export const formatCurrency  = (currencyCode : string,
                                value : number,
                                minimumFractionDigits: number = 0,
                                maximumFractionDigits: number = 0) =>
    new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  }).format(value);