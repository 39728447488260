import React from 'react'
import PrimaryValueCard from './Common/PrimaryValueCard/PrimaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewBookingValueSummary = () => {
	const {getBookingValueSummary, getBookingRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getBookingValueSummary, 0)
	const trendRate = useApiValue(filterState, getBookingRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Gross Booking Value"
			tooltipText="The total gross value of sales our Widget is exposed."
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewBookingValueSummary
