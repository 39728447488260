import React from 'react'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import excelLogoUrl from '../../../../../assets/img/excel-icon.png'
import ProgressSpinner from '../Spinner/ProgressSpinner'
import {ExportData} from '../../../../../types/pulse-data'

const ExportFilter = (props: { exportData: ExportData }) => {
	return (
		<div className="mr-2">
			<Popover>
				<PopoverButton className="
					inline-flex items-center justify-center w-full
					outline-none"
				>
					<div
						className="
							mb-2 pl-2 pr-2 py-[2px]
							bg-white
							border border-pulse-icon-gray rounded-full
							text-nowrap text-sm tracking-[1px] uppercase
							cursor-pointer
							hover:bg-pulse-button-blue
							lg:mb-0"
					>
						<span className="
							align-middle mr-2
							leading-5 text-base text-pulse-icon-gray
							material-symbols-outlined"
						>
							system_update_alt
						</span>
						<span className="align-middle leading-5">Export</span>
					</div>
				</PopoverButton>
				<PopoverPanel anchor="bottom end" className="z-20 text-xs">
					<div className="
						m-[2px] mt-[5px] relative
						bg-white
						border border-pulse-popup-border-gray rounded-lg"
					>
						<div className="flex min-w-44">
							<div className="">
								<div className="flex flex-col px-6 py-5">
									<div className="mb-4">Export Data</div>
									<div
										className="
												mb-2 inline-flex items-center
												cursor-pointer
												hover:text-pulse-blue"
										onClick={props.exportData.exportXLSX}
									>
										<img
											src={excelLogoUrl} alt="Excel"
											className="h-4 mr-3 w-4"
										/>
										<span>EXCEL</span>
									</div>
									<div
										className="
											inline-flex items-center
											cursor-pointer
											hover:text-pulse-blue"
										onClick={props.exportData.exportCSV}
									>
										<span className="
											ml-[-2px] mr-[10px]
											text-black text-xl
											material-symbols-outlined"
										>
											csv
										</span>
										<span>CSV</span>
									</div>
									{props.exportData.csvElement != null && (props.exportData.csvElement)}
								</div>
							</div>
							{props.exportData.isLoading && (
								<div className="flex flex-1 items-center justify-start mt-4">
									<ProgressSpinner/>
								</div>
							)}
						</div>
					</div>
				</PopoverPanel>
			</Popover>
		</div>
	)
}

export default ExportFilter
