import {atom, selector} from 'recoil'
import {IntegrationFilter} from '../types/integration'
import {loggedInUserState} from './auth'
import {autoRefreshState} from './filters'
import {dateFormat} from '../utils/date'
import moment, {Moment} from 'moment'

const keyPrefix = 'integration'

export const integrationFilterState = selector<IntegrationFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromStateIntegration),
			dateTo: get(formattedDateToStateIntegration),
			regions: get(selectedRegionsState),
			commercial: get(selectedCommercialStateMember),
			integrationType: get(selectedIntegrationTypeState),
			loggedInUser: get(loggedInUserState),
			autoRefresh: get(autoRefreshState),
			integrationGuide: get(selectedIntegrationGuideState),
			integrationMigration: get(integrationMigrationState),
            integrationAgreement: get(integrationAgreementState)
		}
	}
})

export const selectedCommercialStateMember = atom<string>({
	key: `${keyPrefix}SelectedCommercialStateMember`,
	default: ''
})

export const selectedRegionsState = atom<number[]>({
	key: `${keyPrefix}SelectedRegionsState`,
	default: []
})

export const selectedIntegrationTypeState = atom<number>({
	key: `${keyPrefix}SelectedIntegrationTypeState`,
	default: -1
})

export const selectedIntegrationGuideState = atom<number>({
	key: `${keyPrefix}SelectedIntegrationGuideState`,
	default: -1
})

export const formattedDateFromStateIntegration = selector<string>({
	key: `${keyPrefix}FormattedDateFromState`,
	get: ({get}) => get(dateFromStateIntegration).format(dateFormat)
})

export const formattedDateToStateIntegration = selector<string>({
	key: `${keyPrefix}FormattedDateToState`,
	get: ({get}) => get(dateToStateIntegration).format(dateFormat)
})

export const dateFromStateIntegration = atom<Moment>({
	key: `${keyPrefix}DateFromState`,
	default: moment().startOf('year')
})

export const dateToStateIntegration = atom<Moment>({
	key: `${keyPrefix}DateToState`,
	default: moment()
})

export const integrationMigrationState = atom<number>({
	key: `${keyPrefix}IntegrationMigrationState`,
	default: -1
})

export const integrationAgreementState = atom<number>({
    key: `${keyPrefix}IntegrationAgreementState`,
    default: -1
})
