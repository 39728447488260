import React, {useEffect, useState} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {integrationFilterState} from '../../../../state/integration'
import {getColorPalette} from '../../../../utils/colors'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'
import {StatusChartData} from '../../../../types/integration'

export const IntegrationStatusChart = () => {
	const {getStatusChartData} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getStatusChartData, {} as StatusChartData)
	const [statusData, setStatusData] = useState<number[]>([])

	useEffect(() => {
		if (data) {
			setStatusData([
				data.notStarted,
				data.integrating,
				data.pendingSignOff,
				data.pendingSignOffByHead,
				data.integrated,
				data.liveCredentialsAvailable,
				data.cancelled,
				data.declineSignOff,
				data.declineSignOffByHead,
			])
		}
	}, [data])

	const chartData = {
		labels: [
			'Not started',
			'Integrating',
			'Pending Sign Off',
			'Pending Sign Off by head',
			'Integrated',
			'Live credentials available',
			'Cancelled',
			'Declined Sign Off',
			'Declined Sign Off by head'
		],
		datasets: [{
			label: 'Status Chart',
			data: statusData,
			backgroundColor: getColorPalette(9),
			hoverOffset: 4
		}]
	}

	return (
		<ChartCard
			title="Integration status"
			titleClass="card-title-warning"
		>
			<Doughnut
				options={{maintainAspectRatio: false}}
				height={400}
				width={400}
				data={chartData}
			/>
		</ChartCard>
	)
}