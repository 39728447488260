export interface Country {
	latLng: number[]
	name: string
}

export const Countries: Country[] = [
	{latLng: [42.546245, 1.601554], name: 'AD'},
	{latLng: [23.424076, 53.847818], name: 'AE'},
	{latLng: [33.93911, 67.709953], name: 'AF'},
	{latLng: [17.060816, -61.796428], name: 'AG'},
	{latLng: [18.220554, -63.068615], name: 'AI'},
	{latLng: [41.153332, 20.168331], name: 'AL'},
	{latLng: [40.069099, 45.038189], name: 'AM'},
	{latLng: [12.226079, -69.060087], name: 'AN'},
	{latLng: [-11.202692, 17.873887], name: 'AO'},
	{latLng: [-75.250973, -0.071389], name: 'AQ'},
	{latLng: [-38.416097, -63.616672], name: 'AR'},
	{latLng: [-14.270972, -170.132217], name: 'AS'},
	{latLng: [47.516231, 14.550072], name: 'AT'},
	{latLng: [-25.274398, 133.775136], name: 'AU'},
	{latLng: [12.52111, -69.968338], name: 'AW'},
	{latLng: [40.143105, 47.576927], name: 'AZ'},
	{latLng: [43.915886, 17.679076], name: 'BA'},
	{latLng: [13.193887, -59.543198], name: 'BB'},
	{latLng: [23.684994, 90.356331], name: 'BD'},
	{latLng: [50.503887, 4.469936], name: 'BE'},
	{latLng: [12.238333, -1.561593], name: 'BF'},
	{latLng: [42.733883, 25.48583], name: 'BG'},
	{latLng: [25.930414, 50.637772], name: 'BH'},
	{latLng: [-3.373056, 29.918886], name: 'BI'},
	{latLng: [9.30769, 2.315834], name: 'BJ'},
	{latLng: [32.321384, -64.75737], name: 'BM'},
	{latLng: [4.535277, 114.727669], name: 'BN'},
	{latLng: [-16.290154, -63.588653], name: 'BO'},
	{latLng: [-14.235004, -51.92528], name: 'BR'},
	{latLng: [25.03428, -77.39628], name: 'BS'},
	{latLng: [27.514162, 90.433601], name: 'BT'},
	{latLng: [-54.423199, 3.413194], name: 'BV'},
	{latLng: [-22.328474, 24.684866], name: 'BW'},
	{latLng: [53.709807, 27.953389], name: 'BY'},
	{latLng: [17.189877, -88.49765], name: 'BZ'},
	{latLng: [56.130366, -106.346771], name: 'CA'},
	{latLng: [-12.164165, 96.870956], name: 'CC'},
	{latLng: [-4.038333, 21.758664], name: 'CD'},
	{latLng: [6.611111, 20.939444], name: 'CF'},
	{latLng: [-0.228021, 15.827659], name: 'CG'},
	{latLng: [46.818188, 8.227512], name: 'CH'},
	{latLng: [7.539989, -5.54708], name: 'CI'},
	{latLng: [-21.236736, -159.777671], name: 'CK'},
	{latLng: [-35.675147, -71.542969], name: 'CL'},
	{latLng: [7.369722, 12.354722], name: 'CM'},
	{latLng: [35.86166, 104.195397], name: 'CN'},
	{latLng: [4.570868, -74.297333], name: 'CO'},
	{latLng: [9.748917, -83.753428], name: 'CR'},
	{latLng: [21.521757, -77.781167], name: 'CU'},
	{latLng: [16.002082, -24.013197], name: 'CV'},
	{latLng: [-10.447525, 105.690449], name: 'CX'},
	{latLng: [35.126413, 33.429859], name: 'CY'},
	{latLng: [49.817492, 15.472962], name: 'CZ'},
	{latLng: [51.165691, 10.451526], name: 'DE'},
	{latLng: [11.825138, 42.590275], name: 'DJ'},
	{latLng: [56.26392, 9.501785], name: 'DK'},
	{latLng: [15.414999, -61.370976], name: 'DM'},
	{latLng: [18.735693, -70.162651], name: 'DO'},
	{latLng: [28.033886, 1.659626], name: 'DZ'},
	{latLng: [-1.831239, -78.183406], name: 'EC'},
	{latLng: [58.595272, 25.013607], name: 'EE'},
	{latLng: [26.820553, 30.802498], name: 'EG'},
	{latLng: [24.215527, -12.885834], name: 'EH'},
	{latLng: [15.179384, 39.782334], name: 'ER'},
	{latLng: [40.463667, -3.74922], name: 'ES'},
	{latLng: [9.145, 40.489673], name: 'ET'},
	{latLng: [61.92411, 25.748151], name: 'FI'},
	{latLng: [-16.578193, 179.414413], name: 'FJ'},
	{latLng: [-51.796253, -59.523613], name: 'FK'},
	{latLng: [7.425554, 150.550812], name: 'FM'},
	{latLng: [61.892635, -6.911806], name: 'FO'},
	{latLng: [46.227638, 2.213749], name: 'FR'},
	{latLng: [-0.803689, 11.609444], name: 'GA'},
	{latLng: [55.378051, -3.435973], name: 'GB'},
	{latLng: [12.262776, -61.604171], name: 'GD'},
	{latLng: [42.315407, 43.356892], name: 'GE'},
	{latLng: [3.933889, -53.125782], name: 'GF'},
	{latLng: [49.465691, -2.585278], name: 'GG'},
	{latLng: [7.946527, -1.023194], name: 'GH'},
	{latLng: [36.137741, -5.345374], name: 'GI'},
	{latLng: [71.706936, -42.604303], name: 'GL'},
	{latLng: [13.443182, -15.310139], name: 'GM'},
	{latLng: [9.945587, -9.696645], name: 'GN'},
	{latLng: [16.995971, -62.067641], name: 'GP'},
	{latLng: [1.650801, 10.267895], name: 'GQ'},
	{latLng: [39.074208, 21.824312], name: 'GR'},
	{latLng: [-54.429579, -36.587909], name: 'GS'},
	{latLng: [15.783471, -90.230759], name: 'GT'},
	{latLng: [13.444304, 144.793731], name: 'GU'},
	{latLng: [11.803749, -15.180413], name: 'GW'},
	{latLng: [4.860416, -58.93018], name: 'GY'},
	{latLng: [31.354676, 34.308825], name: 'GZ'},
	{latLng: [22.396428, 114.109497], name: 'HK'},
	{latLng: [-53.08181, 73.504158], name: 'HM'},
	{latLng: [15.199999, -86.241905], name: 'HN'},
	{latLng: [45.1, 15.2], name: 'HR'},
	{latLng: [18.971187, -72.285215], name: 'HT'},
	{latLng: [47.162494, 19.503304], name: 'HU'},
	{latLng: [-0.789275, 113.921327], name: 'ID'},
	{latLng: [53.41291, -8.24389], name: 'IE'},
	{latLng: [31.046051, 34.851612], name: 'IL'},
	{latLng: [54.236107, -4.548056], name: 'IM'},
	{latLng: [20.593684, 78.96288], name: 'IN'},
	{latLng: [-6.343194, 71.876519], name: 'IO'},
	{latLng: [33.223191, 43.679291], name: 'IQ'},
	{latLng: [32.427908, 53.688046], name: 'IR'},
	{latLng: [64.963051, -19.020835], name: 'IS'},
	{latLng: [41.87194, 12.56738], name: 'IT'},
	{latLng: [49.214439, -2.13125], name: 'JE'},
	{latLng: [18.109581, -77.297508], name: 'JM'},
	{latLng: [30.585164, 36.238414], name: 'JO'},
	{latLng: [36.204824, 138.252924], name: 'JP'},
	{latLng: [-0.023559, 37.906193], name: 'KE'},
	{latLng: [41.20438, 74.766098], name: 'KG'},
	{latLng: [12.565679, 104.990963], name: 'KH'},
	{latLng: [-3.370417, -168.734039], name: 'KI'},
	{latLng: [-11.875001, 43.872219], name: 'KM'},
	{latLng: [17.357822, -62.782998], name: 'KN'},
	{latLng: [40.339852, 127.510093], name: 'KP'},
	{latLng: [35.907757, 127.766922], name: 'KR'},
	{latLng: [29.31166, 47.481766], name: 'KW'},
	{latLng: [19.513469, -80.566956], name: 'KY'},
	{latLng: [48.019573, 66.923684], name: 'KZ'},
	{latLng: [19.85627, 102.495496], name: 'LA'},
	{latLng: [33.854721, 35.862285], name: 'LB'},
	{latLng: [13.909444, -60.978893], name: 'LC'},
	{latLng: [47.166, 9.555373], name: 'LI'},
	{latLng: [7.873054, 80.771797], name: 'LK'},
	{latLng: [6.428055, -9.429499], name: 'LR'},
	{latLng: [-29.609988, 28.233608], name: 'LS'},
	{latLng: [55.169438, 23.881275], name: 'LT'},
	{latLng: [49.815273, 6.129583], name: 'LU'},
	{latLng: [56.879635, 24.603189], name: 'LV'},
	{latLng: [26.3351, 17.228331], name: 'LY'},
	{latLng: [31.791702, -7.09262], name: 'MA'},
	{latLng: [43.750298, 7.412841], name: 'MC'},
	{latLng: [47.411631, 28.369885], name: 'MD'},
	{latLng: [42.708678, 19.37439], name: 'ME'},
	{latLng: [-18.766947, 46.869107], name: 'MG'},
	{latLng: [7.131474, 171.184478], name: 'MH'},
	{latLng: [41.608635, 21.745275], name: 'MK'},
	{latLng: [17.570692, -3.996166], name: 'ML'},
	{latLng: [21.913965, 95.956223], name: 'MM'},
	{latLng: [46.862496, 103.846656], name: 'MN'},
	{latLng: [22.198745, 113.543873], name: 'MO'},
	{latLng: [17.33083, 145.38469], name: 'MP'},
	{latLng: [14.641528, -61.024174], name: 'MQ'},
	{latLng: [21.00789, -10.940835], name: 'MR'},
	{latLng: [16.742498, -62.187366], name: 'MS'},
	{latLng: [35.937496, 14.375416], name: 'MT'},
	{latLng: [-20.348404, 57.552152], name: 'MU'},
	{latLng: [3.202778, 73.22068], name: 'MV'},
	{latLng: [-13.254308, 34.301525], name: 'MW'},
	{latLng: [23.634501, -102.552784], name: 'MX'},
	{latLng: [4.210484, 101.975766], name: 'MY'},
	{latLng: [-18.665695, 35.529562], name: 'MZ'},
	{latLng: [-22.95764, 18.49041], name: 'NA'},
	{latLng: [-20.904305, 165.618042], name: 'NC'},
	{latLng: [17.607789, 8.081666], name: 'NE'},
	{latLng: [-29.040835, 167.954712], name: 'NF'},
	{latLng: [9.081999, 8.675277], name: 'NG'},
	{latLng: [12.865416, -85.207229], name: 'NI'},
	{latLng: [52.132633, 5.291266], name: 'NL'},
	{latLng: [60.472024, 8.468946], name: 'NO'},
	{latLng: [28.394857, 84.124008], name: 'NP'},
	{latLng: [-0.522778, 166.931503], name: 'NR'},
	{latLng: [-19.054445, -169.867233], name: 'NU'},
	{latLng: [-40.900557, 174.885971], name: 'NZ'},
	{latLng: [21.512583, 55.923255], name: 'OM'},
	{latLng: [8.537981, -80.782127], name: 'PA'},
	{latLng: [-9.189967, -75.015152], name: 'PE'},
	{latLng: [-17.679742, -149.406843], name: 'PF'},
	{latLng: [-6.314993, 143.95555], name: 'PG'},
	{latLng: [12.879721, 121.774017], name: 'PH'},
	{latLng: [30.375321, 69.345116], name: 'PK'},
	{latLng: [51.919438, 19.145136], name: 'PL'},
	{latLng: [46.941936, -56.27111], name: 'PM'},
	{latLng: [-24.703615, -127.439308], name: 'PN'},
	{latLng: [18.220833, -66.590149], name: 'PR'},
	{latLng: [31.952162, 35.233154], name: 'PS'},
	{latLng: [39.399872, -8.224454], name: 'PT'},
	{latLng: [7.51498, 134.58252], name: 'PW'},
	{latLng: [-23.442503, -58.443832], name: 'PY'},
	{latLng: [25.354826, 51.183884], name: 'QA'},
	{latLng: [-21.115141, 55.536384], name: 'RE'},
	{latLng: [45.943161, 24.96676], name: 'RO'},
	{latLng: [44.016521, 21.005859], name: 'RS'},
	{latLng: [61.52401, 105.318756], name: 'RU'},
	{latLng: [-1.940278, 29.873888], name: 'RW'},
	{latLng: [23.885942, 45.079162], name: 'SA'},
	{latLng: [-9.64571, 160.156194], name: 'SB'},
	{latLng: [-4.679574, 55.491977], name: 'SC'},
	{latLng: [12.862807, 30.217636], name: 'SD'},
	{latLng: [60.128161, 18.643501], name: 'SE'},
	{latLng: [1.352083, 103.819836], name: 'SG'},
	{latLng: [-24.143474, -10.030696], name: 'SH'},
	{latLng: [46.151241, 14.995463], name: 'SI'},
	{latLng: [77.553604, 23.670272], name: 'SJ'},
	{latLng: [48.669026, 19.699024], name: 'SK'},
	{latLng: [8.460555, -11.779889], name: 'SL'},
	{latLng: [43.94236, 12.457777], name: 'SM'},
	{latLng: [14.497401, -14.452362], name: 'SN'},
	{latLng: [5.152149, 46.199616], name: 'SO'},
	{latLng: [3.919305, -56.027783], name: 'SR'},
	{latLng: [0.18636, 6.613081], name: 'ST'},
	{latLng: [13.794185, -88.89653], name: 'SV'},
	{latLng: [34.802075, 38.996815], name: 'SY'},
	{latLng: [-26.522503, 31.465866], name: 'SZ'},
	{latLng: [21.694025, -71.797928], name: 'TC'},
	{latLng: [15.454166, 18.732207], name: 'TD'},
	{latLng: [-49.280366, 69.348557], name: 'TF'},
	{latLng: [8.619543, 0.824782], name: 'TG'},
	{latLng: [15.870032, 100.992541], name: 'TH'},
	{latLng: [38.861034, 71.276093], name: 'TJ'},
	{latLng: [-8.967363, -171.855881], name: 'TK'},
	{latLng: [-8.874217, 125.727539], name: 'TL'},
	{latLng: [38.969719, 59.556278], name: 'TM'},
	{latLng: [33.886917, 9.537499], name: 'TN'},
	{latLng: [-21.178986, -175.198242], name: 'TO'},
	{latLng: [38.963745, 35.243322], name: 'TR'},
	{latLng: [10.691803, -61.222503], name: 'TT'},
	{latLng: [-7.109535, 177.64933], name: 'TV'},
	{latLng: [23.69781, 120.960515], name: 'TW'},
	{latLng: [-6.369028, 34.888822], name: 'TZ'},
	{latLng: [48.379433, 31.16558], name: 'UA'},
	{latLng: [1.373333, 32.290275], name: 'UG'},
	{latLng: [], name: 'UM'},
	{latLng: [37.09024, -95.712891], name: 'US'},
	{latLng: [-32.522779, -55.765835], name: 'UY'},
	{latLng: [41.377491, 64.585262], name: 'UZ'},
	{latLng: [41.902916, 12.453389], name: 'VA'},
	{latLng: [12.984305, -61.287228], name: 'VC'},
	{latLng: [6.42375, -66.58973], name: 'VE'},
	{latLng: [18.420695, -64.639968], name: 'VG'},
	{latLng: [18.335765, -64.896335], name: 'VI'},
	{latLng: [14.058324, 108.277199], name: 'VN'},
	{latLng: [-15.376706, 166.959158], name: 'VU'},
	{latLng: [-13.768752, -177.156097], name: 'WF'},
	{latLng: [-13.759029, -172.104629], name: 'WS'},
	{latLng: [42.602636, 20.902977], name: 'XK'},
	{latLng: [15.552727, 48.516388], name: 'YE'},
	{latLng: [-12.8275, 45.166244], name: 'YT'},
	{latLng: [-30.559482, 22.937506], name: 'ZA'},
	{latLng: [-13.133897, 27.849332], name: 'ZM'},
	{latLng: [-19.015438, 29.154857], name: 'ZW'},
]
