import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotValueCard from './Common/TrustPilotValueCard/TrustPilotValueCard'

const RefundAutoApprovalSummary = () => {
	const {getRefundAutoApproveRate} = usePulseApi()
	const value = useApiValue(filterState, getRefundAutoApproveRate, 0)

	return (
		<TrustPilotValueCard
			value={`${value.data}%`}
			header="% of Instant Refunds"
			tooltipText="The percentage of AI automatically processed refunds."
			isLoading={value.isLoading}
		/>
	)
}

export default RefundAutoApprovalSummary
