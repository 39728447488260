import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import moment from 'moment/moment'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {
	dateFromState,
	dateToState,
	formattedDateFromState,
	formattedDateToState,
	selectedDateRangeState
} from '../../../../../state/pulse-filters'
import {calendarDatesState, Value, ValuePiece} from '../../../../../state/pulse'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import Calendar from 'react-calendar'

const CalendarDatesFilter = () => {
	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const date = useRecoilValue(selectedDateRangeState)
	const [calendarDates, setCalendarDates] = useRecoilState(calendarDatesState)
	const formattedDateFrom = useRecoilValue(formattedDateFromState)
	const formattedDateTo = useRecoilValue(formattedDateToState)

	const handleCalendarSelection = (dates: Value) => {
		const [from, to] = (dates as [ValuePiece, ValuePiece])

		setCalendarDates(dates)
		setDateFrom(moment(from))
		setDateTo(moment(to))
	}

	return (
		<div>
			<Popover className={classNames('left-4 mr-2 top-2',
				'lg:left-0 lg:static lg:top-0', {
					'absolute': date === dateRange.Custom
				})}
			>
				<PopoverButton className="
					inline-flex items-center justify-center w-full
					outline-none"
				>
					<div
						className={classNames('mb-2 px-3 py-[2px]',
							'text-nowrap text-sm tracking-[1px] uppercase',
							'border border-pulse-icon-gray rounded-full',
							'cursor-pointer',
							'hover:bg-pulse-button-blue',
							'lg:mb-0', {
								'bg-pulse-button-blue': date === dateRange.Custom,
								'bg-white': date !== dateRange.Custom
							})}
					>
						<span
							className="
								align-middle mr-1 mb-[2px]
								leading-5 text-base text-pulse-icon-gray
								material-symbols-outlined"
						>
							calendar_month
						</span>
						{date !== dateRange.Custom && (
							<span className="align-middle leading-5">Custom</span>
						)}
						{date === dateRange.Custom && (
							<span className="align-middle leading-5">{`${formattedDateFrom} - ${formattedDateTo}`}</span>
						)}
					</div>
				</PopoverButton>
				<PopoverPanel anchor="bottom start" className="mt-2 z-20">
					<Calendar
						className="
							bg-white drop-shadow-[1px_1px_1px_rgba(0,0,0,0.10)]
							border border-pulse-popup-border-gray rounded-lg"
						selectRange={true}
						onChange={handleCalendarSelection}
						value={calendarDates}
						locale="en-US"
					/>
				</PopoverPanel>
			</Popover>
		</div>
	)
}

export default CalendarDatesFilter
