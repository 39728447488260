import {PulseChartDataModel} from '../../../types/pulse-data'
import {graphFilterName, graphRevenue, graphValueDemographics, graphVolumeDemographics} from '../../../types/pulse'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'

export default function useGetCustomerTrendsChartData(recoilValue: { data: PulseChartDataModel; isLoading: boolean }) {
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getTitles = () => {
		return graphFilters.map((filter) => graphFilterName[filter])
	}

	const getData = () => {
		if (!recoilValue.data.countries) {
			return []
		}

		const data: string[][] = []

		for (let i = 0; i < recoilValue.data.countries.length; i++) {
			const country = recoilValue.data.countries[i]

			const record: string[] = []
			record.push(country.countryName || '')

			for (let filter in graphFilters) {
				const graphFilter = graphFilters[filter]
				const value = country.chartData && country.chartData[graphFilter as number]

				if (graphValueDemographics.includes(graphFilter)) {
					record.push(formatCurrency(coreCurrency, value || 0))
				}

				if (graphVolumeDemographics.includes(graphFilter)) {
					record.push((value || 0).toString())
				}

				if (graphRevenue.includes(graphFilter)) {
					record.push(formatCurrency(coreCurrency, value || 0, 0, 2))
				}
			}

			data.push(record)
		}

		return data
	}

	return {
		getData,
		getTitles
	}
}
