import React, {useEffect, useState} from "react";
import {selectedRegionsState} from "../../../../state/integration";
import {MultiSelectFilterItem} from "../../../../components/Dashboard/SelectFilter";
import {useRecoilState} from "recoil";
import {MemberRegion} from "../../../../types";
//import {OptionTypeBase, ValueType} from "react-select/src/types";
import {FilterCard} from "../../../../components/Card/FilterCard";
import Select from "react-select";
import classNames from "classnames";

const MAX_VISIBLE_REGIONS = 5

export const RegionFilter = () => {
	const [listOpen, setListOpen] = useState<boolean>(false)
	const [selectedRegions, setSelectedRegions] = useRecoilState(selectedRegionsState);
	const [items, setItems] = useState<MultiSelectFilterItem[]>([])

	const memberRegions: MultiSelectFilterItem[] = Object.entries(MemberRegion)
		.filter(([key, value]) => typeof value === 'number')
		.map(([key, value]) => ({
			value: value as number,
			label: key,
			isSelected: false,
		}));

	useEffect(() => {
		const next = memberRegions.map(item => ({
			...item,
			isSelected: selectedRegions.includes(item.value),
		}));
		setItems(next);
	}, [selectedRegions]);

	const toggleList = () => setListOpen(curr => !curr)

/*	const handleSelectChange = (newValue: ValueType<OptionTypeBase, boolean>) => {
		const selectedRegions = (newValue as MultiSelectFilterItem[])
			.filter(item => item.value != -1)
			.map((item) => Number(item.value));

		setSelectedRegions((regions) => {
			if (!selectedRegions.length && regions.length === 1) {
				return [];
			}

			const updateRegions = Array.from(new Set([...regions, ...selectedRegions]));
			if (!selectedRegions.length && regions.length > 1) {
				return updateRegions.slice(1);
			}

			return updateRegions.length > 0 ? updateRegions : [];
		});
	};*/

	const selectedItems = items.filter(x => x.isSelected)
	const menuItems = items.filter(x => !x.isSelected)

	const getItemCountLabel = () => {
		const totalCount = selectedItems.length;
		if (totalCount > 1) {
			return `+${totalCount - 1} items`;
		}
		return '';
	};

	const isOptionDisabled = (option: MultiSelectFilterItem) => {
		return selectedRegions.length >= MAX_VISIBLE_REGIONS && !selectedRegions.includes(option.value);
	};

	return (
		<FilterCard title='Regions'>
			<div>
				<Select
					isMulti
					className="react-select"
					classNamePrefix="react-select"
					options={menuItems}
					value={selectedItems[0]}
					//onChange={handleSelectChange}
					components={{}}
					isOptionDisabled={isOptionDisabled}
				/>
				<div className="multi-value-filter-container w-100">
					<div className="multi-value-filter">
						<div className={classNames('multi-value-pill-container cursor-pointer', {'is-open': !listOpen})}
						     onClick={toggleList}>
							<span className="multi-value-filter-pill">{getItemCountLabel()}</span>
						</div>
						<div className={classNames('multi-value-pill-container cursor-pointer', {'is-open': listOpen})}
						     onClick={toggleList}>
							<div className="d-flex flex-row">
								{
									selectedItems?.slice(1, MAX_VISIBLE_REGIONS).map((x) => {
										return (
											<div className="multi-value-filter-pill">{x.label}</div>
										)
									})
								}
								{
									(selectedItems && selectedItems.length > MAX_VISIBLE_REGIONS) && (
										<>
											<div className="multi-value-filter-pill">...</div>
											<div className="multi-value-filter-pill">
												{selectedItems.length - MAX_VISIBLE_REGIONS}
											</div>
										</>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</FilterCard>
	);
};