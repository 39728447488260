import {usePulseApi} from '../../../hooks/api/pulse'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'

export default function useExportOverviewData(): ExportData {
	const {
		getBookingValueSummary,
		getRefundProtectValueSummary,
		getProfitValueSummary,
		getAverageConversionRate,
		getAverageTransactionRate,
		getTransactionRevenuePerDollar,
		getAverageTransactionRevenue
	} = usePulseApi()
	const filter = useRecoilValue(filterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getData = async (): Promise<any> => {
		const formattedRevenuePerDollar = formatCurrency(coreCurrency, await getTransactionRevenuePerDollar(filter), 0, 2)
		const currencySymbol = formattedRevenuePerDollar
			.replace(/\d/g, '')
			.replace('.', '')
			.trim()

		return [
			{
				Property: 'Gross Booking Value',
				Value: formatCurrency(coreCurrency, await getBookingValueSummary(filter))
			},
			{
				Property: 'Refund Protect Value',
				Value: formatCurrency(coreCurrency, await getRefundProtectValueSummary(filter))
			},
			{
				Property: 'Your Revenue',
				Value: formatCurrency(coreCurrency, await getProfitValueSummary(filter))
			},
			{
				Property: 'Average Conversion Rate',
				Value: await getAverageConversionRate(filter) + '%'
			},
			{
				Property: 'Average Price to Customers',
				Value: await getAverageTransactionRate(filter) + '%'
			},
			{
				Property: 'Average Revenue per Transaction',
				Value: formatCurrency(coreCurrency, await getAverageTransactionRevenue(filter), 0, 2)
			},
			{
				Property: `Revenue per ${currencySymbol}`,
				Value: formattedRevenuePerDollar
			},
		]
	}

	const getFileName = () => `Overview-${moment().toISOString()}`
	const getHeaders = () => ['Property', 'Value']

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
