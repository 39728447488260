import React from 'react'
import SecondaryValueCard from './Common/SecondaryValueCard/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewConversionRateSummary = () => {
	const {getAverageConversionRate} = usePulseApi()
	const value = useApiValue(filterState, getAverageConversionRate, 0)

	return (
		<SecondaryValueCard
			value={value.data + '%'}
			header="Average Conversion Rate"
			tooltipText="The average percentage of the Refund Protect opted-in volume by the Gross Booking Volume."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewConversionRateSummary
