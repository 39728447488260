import React from 'react'
import SummaryCardWrapper from '../../../../components/Dashboard/SummaryCardWrapper'
import {StatusCompletedSummary} from './StatusCompletedSummary'
import {StatusProgressSummary} from './StatusProgressSummary'
import {TimeSummary} from './TimeSummary'
import {ExpectedMrrSummary} from './ExpectedMrrSummary'
import {ActualMrrSummary} from './ActualMrrSummary'

const Summary = () => {

	return (
		<div className="d-flex justify-content-between">
			<SummaryCardWrapper className="px-1">
				<StatusCompletedSummary/>
			</SummaryCardWrapper>

			<SummaryCardWrapper className="px-1">
				<StatusProgressSummary/>
			</SummaryCardWrapper>

			<SummaryCardWrapper className="px-1">
				<TimeSummary/>
			</SummaryCardWrapper>

			<SummaryCardWrapper className="px-1">
				<ExpectedMrrSummary/>
			</SummaryCardWrapper>

			<SummaryCardWrapper className="px-1">
				<ActualMrrSummary/>
			</SummaryCardWrapper>
		</div>
	)
}

export {Summary}