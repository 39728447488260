import React from 'react'
import {useRecoilValue} from 'recoil'
import {partnershipButtonReferenceState} from '../../../state/pulse'

const UpsellOverlay = () => {
	const partnershipButtonReference = useRecoilValue(partnershipButtonReferenceState)
	const handleClick = () => {
		partnershipButtonReference?.click()
	}

	return (
		<div className="
			absolute bottom-0 left-0 right-0 top-0
			backdrop-blur-sm"
		>
			<div className="flex h-full items-center justify-center w-full">
				<div className="flex flex-col items-center justify-center">
					<p className="
						mb-3 w-10/12
						font-medium text-center text-sm"
					>
						Want to take your revenue to the next level?
					</p>
					<div
						className="
							flex items-center justify-center px-4 py-2
							bg-pulse-blue rounded-full
							text-white uppercase
							cursor-pointer"
						onClick={handleClick}
					>
						<span className="mr-2 material-symbols-outlined ">lock</span>
						<span className="text-sm w-20">Unlock Feature</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UpsellOverlay
