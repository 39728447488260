import {Moment} from 'moment';

export enum Dashboards {
	Commission,
	Performance,
	Member,
	LivePerformance,
	Sales,
	ProtectFinancial,
	DynamicPerformance,
	DynamicPricing,
	Document,
	DynamicContent,
	Refund,
	InsuranceProvider,
	Upload,
	Integration,
	PulseOverview,
	PulseRevenueTrends,
	PulseCustomerTrends,
	PulseTransactions,
	PulseSupport,
	PulseSettings
}

export enum Roles {
	CommissionDashboardUser = 'CommissionDashboardUser',
	MemberDashboardUser = 'MemberDashboardUser',
	LivePerformanceDashboardUser = 'LivePerformanceDashboardUser',
	SalesDashboardUser = 'SalesDashboardUser',
	ProtectFinancialDashboardUser = 'ProtectFinancialDashboardUser',
	DynamicDashboardUser = 'DynamicDashboardUser',
	DynamicDashboardAdmin = 'DynamicDashboardAdmin',
	DocumentDashboardUser = 'DocumentsDashboardUser',
	RefundDashboardUser = 'RefundDashboardUser',
	InsuranceProviderDashboardUser = 'InsuranceProviderDashboardUser',
	UploadDashboardUser = 'UploadDashboardUser',
	IntegrationDashboardUser = 'IntegrationDashboardUser',
	PulseDashboardUser = 'PulseDashboardUser'
}

export const dashboardPaths: Record<Dashboards, string> = {
	[Dashboards.Commission]: '/commission',
	[Dashboards.Performance]: '/',
	[Dashboards.Member]: '/member',
	[Dashboards.LivePerformance]: '/live-performance',
	[Dashboards.Sales]: '/sales',
	[Dashboards.ProtectFinancial]: '/protect-financial',
	[Dashboards.DynamicPerformance]: '/dynamic-performance',
	[Dashboards.DynamicPricing]: '/dynamic-pricing',
	[Dashboards.Document]: '/document',
	[Dashboards.DynamicContent]: '/dynamic-content',
	[Dashboards.Refund]: '/refund',
	[Dashboards.InsuranceProvider]: '/insurance-provider',
	[Dashboards.Upload]: '/upload',
	[Dashboards.Integration]: '/integration',
	[Dashboards.PulseOverview]: '/pulse/overview',
	[Dashboards.PulseRevenueTrends]: '/pulse/revenue-trends',
	[Dashboards.PulseCustomerTrends]: '/pulse/customer-trends',
	[Dashboards.PulseTransactions]: '/pulse/transactions',
	[Dashboards.PulseSupport]: '/pulse/support',
	[Dashboards.PulseSettings]: '/pulse/settings'
}

export const dashboardRoles: Record<Dashboards, string> = {
	[Dashboards.Commission]: Roles.CommissionDashboardUser,
	[Dashboards.Performance]: 'NotReady',
	[Dashboards.Member]: Roles.MemberDashboardUser,
	[Dashboards.LivePerformance]: Roles.LivePerformanceDashboardUser,
	[Dashboards.Sales]: Roles.SalesDashboardUser,
	[Dashboards.ProtectFinancial]: Roles.ProtectFinancialDashboardUser,
	[Dashboards.DynamicPerformance]: Roles.DynamicDashboardUser,
	[Dashboards.DynamicPricing]: Roles.DynamicDashboardAdmin,
	[Dashboards.Document]: Roles.DocumentDashboardUser,
	[Dashboards.DynamicContent]: Roles.DynamicDashboardAdmin,
	[Dashboards.Refund]: Roles.RefundDashboardUser,
	[Dashboards.InsuranceProvider]: Roles.InsuranceProviderDashboardUser,
	[Dashboards.Upload]: Roles.UploadDashboardUser,
	[Dashboards.Integration]: Roles.IntegrationDashboardUser,
	[Dashboards.Upload]: Roles.UploadDashboardUser,
	[Dashboards.PulseOverview]: Roles.PulseDashboardUser,
	[Dashboards.PulseTransactions]: Roles.PulseDashboardUser,
	[Dashboards.PulseRevenueTrends]: Roles.PulseDashboardUser,
	[Dashboards.PulseCustomerTrends]: Roles.PulseDashboardUser,
	[Dashboards.PulseTransactions]: Roles.PulseDashboardUser,
	[Dashboards.PulseSupport]: Roles.PulseDashboardUser,
	[Dashboards.PulseSettings]: Roles.PulseDashboardUser
}

export enum productType {
	All,
	EventProtect,
	RefundProtect
}

export enum NotificationMessageType {
	success,
	info,
	warning,
	error
}

export enum RegionType {
	All,
	Asia,
	Europe,
	NorthAmerica,
	SouthAmerica,
	CentralAmerica,
	Nordics,
	MiddleEastAfrica,
	India,
	China,
	Oceania
}

export enum MemberRegion {
	CentralAmerica = 3,
	SouthAmerica = 4,
	China = 5,
	NorthAsia = 6,
	SouthAsia = 7,
	Oceania = 8,
	Europe = 10,
	India = 12,
	Brazil = 13,
	NorthAmerica = 14,
	MiddleEast = 15,
	Africa = 16
}

export interface NotificationMessage {
	message: string,
	type: NotificationMessageType,
	stayOpen?: boolean,
	timeout?: number
}

export interface CommissionFilter extends Record<string, any> {
	dateFrom: string | null,
	dateTo: string | null,
	userId: number | null,
	memberId: number | null,
	productType?: ProductType | null,
	loggedInUser: string | undefined,
	salesTeamId?: number | null
}

export interface MemberFilter extends Record<string, any> {
	dateFrom: string,
	dateTo: string,
	memberId?: number,
	eventId: any,
	mainProductId: number,
	productId: number,
	productTypeCode: string,
	countryCode: string,
	currencyCode: string,
	coreCurrencyCode: string,
	loggedInUser: string | undefined
}

export interface InsuranceProviderFilter extends Record<string, any> {
	dateFrom: string,
	dateTo: string,
	insuranceProviderId: number,
	eventIds: number[],
	productId: number,
	rpTypeId: number,
	epTypeId: number,
	locationId: string,
	currencyCode: string,
	coreCurrencyCode: string,
	loggedInUser: string | undefined,
	insuranceProviderDataIds: string,
	brokerDataIds: string
}

export interface DocumentFilter extends Record<string, any> {
	actorId: number | null,
	loggedInUser: string | undefined,
	documentType: number | null,
	dateFrom: string,
	dateTo: string,
	insuranceProviderDataIds: string,
	isActorInsurer: boolean | null
}

export interface TransactionTableFilter extends Record<string, any> {
	dateFrom: string,
	dateTo: string,
	memberId?: number,
	eventId: any,
	mainProductId: number,
	productId: number,
	productTypeCode: string,
	countryCode: string,
	currencyCode: string,
	coreCurrencyCode: string,
	loggedInUser: string | undefined,
	transactionSearchText: string,
	autoRefresh: number
}


export interface DropdownData {
	id: number,
	name: string
}

export interface User extends DropdownData {
}

export interface Member extends DropdownData {
}

export interface Type extends DropdownData {
}

export interface Actor extends DropdownData {
	actorId: number,
	actorName: string,
	isMember: boolean,
	isInsurer: boolean
}

export interface Document extends DropdownData {
}

export interface ProductType extends DropdownData {
	productId: number,
	productCode: string,
	productName: string
}

export interface Event extends DropdownData {
	eventId: number,
	eventName: string
}

export interface Location extends DropdownData {
	transactionCountryId: number,
	transactionCountryCode: string,
	transactionCountryName: string
}

export interface Region extends DropdownData {
	value: number
}

export interface MainProduct extends DropdownData {
	productName: string
}

export interface Currency extends DropdownData {
	currencyCode: string,
	symbol: string
}

export interface Regions {
	MemberId: number,
	MemberName: string
}

export interface UploadActionType extends DropdownData {
}

export interface Timezone extends DropdownData {
}

export interface SalesTeam extends DropdownData {
}

export interface InsuranceProvider extends DropdownData {
}

export interface ChartData {
	commission: number[],
	revenue: number[]
}

export interface ConversionByValueBand {
	ValueBand: string,
	ConversionRate: number
}

export interface DateFilterToggle {
	label: string,
	dateFrom: Moment,
	dateTo: Moment
}

export interface RegionPerformanceData {
	name: string,
	weekTotal: number,
	monthTotal: number,
	yearTotal: number
}

export interface SummaryResultData {
	TotalEventCount: any,
	TotalProtectionCost: any,
	TotalProtectionRate: any,
	TotalTransactionValue: any,
	TotalTransactionVolume: any
}

export type RegionMaps = {
	RegionSummaries: RegionSummaries
}

export interface RegionSummaries extends RegionMaps {
	Europe: Europe;
	Unknown: Unknown,
	Africa: Africa,
	"North America": NorthAmerica,
	"South America": SouthAmerica,
	Asia: Asia,
	Oceania: Oceania
}

export interface Region {
	code: string
	id: number
	name: string
}

export interface RegionSummary {
	countriesData: CountriesData[],
	epTransactionProtectionValueWTD: number,
	epTransactionProtectionValueMTD: number,
	epTransactionProtectionValueYTD: number,
	epTransactionValueInsuredWTD: number,
	epTransactionValueInsuredMTD: number,
	epTransactionValueInsuredYTD: number,
	epTransactionVolumeInsuredWTD: number,
	epTransactionVolumeInsuredMTD: number,
	epTransactionVolumeInsuredYTD: number,
	epTransactionCountInsuredWTD: number,
	epTransactionCountInsuredMTD: number,
	epTransactionCountInsuredYTD: number,
	regionId: number,
	regionName: string,
	rpTransactionProtectionValueWTD: number,
	rpTransactionProtectionValueMTD: number,
	rpTransactionProtectionValueYTD: number,
	rpTransactionValueInsuredWTD: number,
	rpTransactionValueInsuredMTD: number,
	rpTransactionValueInsuredYTD: number,
	rpTransactionVolumeInsuredWTD: number,
	rpTransactionVolumeInsuredMTD: number,
	rpTransactionVolumeInsuredYTD: number,
	rpTransactionCountInsuredWTD: number,
	rpTransactionCountInsuredMTD: number,
	rpTransactionCountInsuredYTD: number
}

export interface Europe extends RegionSummary {
}

export interface Unknown extends RegionSummary {
}

export interface Africa extends RegionSummary {
}

export interface NorthAmerica extends RegionSummary {
}

export interface SouthAmerica extends RegionSummary {
}

export interface Asia extends RegionSummary {
}

export interface Oceania extends RegionSummary {
}

export interface CountriesData {
	countryCodeTwoLetter: string,
	countryCodeThreeLetter: string,
	countryName: string,
	epTransactionProtectionValue: number,
	epTransactionValueInsured: number,
	epTransactionVolumeInsured: number,
	epTransactionCountInsured: number,
	rpTransactionProtectionValue: number,
	rpTransactionValueInsured: number,
	rpTransactionVolumeInsured: number,
	rpTransactionCountInsured: number,
	transactionDate: Date
}

export interface ChartSummaryData {
	totalDtd: number
	totalMtd: number
	totalWtd: number
	totalYtd: number
}

export interface ProtectFinancialFilter extends Record<string, string> {
	dateFrom: string,
	dateTo: string
}

export const defaultChartSummaryData: ChartSummaryData = {
	totalDtd: 0, totalMtd: 0, totalWtd: 0, totalYtd: 0
}
