import React from 'react'
import SecondaryValueCard from './Common/SecondaryValueCard/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'

const OverviewRevenuePerTransactionSummary = () => {
	const {getAverageTransactionRevenue} = usePulseApi()
	const value = useApiValue(filterState, getAverageTransactionRevenue, 0)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	return (
		<SecondaryValueCard
			value={formatCurrency(coreCurrency, value.data, 0, 4)}
			header="Average Revenue per Transaction"
			tooltipText="The average shared revenue per transaction."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewRevenuePerTransactionSummary
