import React from 'react'
import SecondaryValueCard from './Common/SecondaryValueCard/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewPriceToCustomerSummary = () => {
	const {getAverageTransactionRate} = usePulseApi()
	const value = useApiValue(filterState, getAverageTransactionRate, 0)

	return (
		<SecondaryValueCard
			value={value.data + '%'}
			header="Average Price to Customers"
			tooltipText="The average Refund Protect fee offered through our widget."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewPriceToCustomerSummary
