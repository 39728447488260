export const randomNumber = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const minutesToMilliseconds = (minutes: number): number => minutes * 60000;
export const millisecondsToMinutes = (milliseconds: number): number => milliseconds / 60000;

export const toPosition = (int: number | string): string => {
  if (int.toString() === '0') {
    return 'N/A';
  }

  const intArr = int.toString();

  const useThAnyway = intArr.length > 1 && intArr[intArr.length - 2] === '1';

  const suffix = () => {
    if (useThAnyway) {
      return 'th';
    }

    switch (intArr[intArr.length - 1]) {
      case '1':
        return 'st'
      case '2':
        return 'nd'
      case '3':
        return 'rd'
      default:
        return 'th'
    }
  }

  return `${int}${suffix()}`
}

export const formatNumber: (value: string, fractionDigits: number) => string = (value: string, fractionDigits: number = 2) => {
  const numVal = Number(value);

  return !isNaN(numVal) ? numVal.toLocaleString('en-US', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  }) : value;
};

export const formatNumberMember  = (value : number) =>
    new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 3,
    }).format(value);

export const toGbp = (value: string) => `£${formatNumber(value, 2)}`;
export const toUsd = (value: string, fractionDigits: number = 2) => `$${formatNumber(value, fractionDigits)}`;
export const toPercentage = (value: string, fractionDigits: number = 2) => `${formatNumber(value, fractionDigits)}%`;
export const formatNumberWithoutSeparator = (value: string | number) => parseInt(value.toString().replace(/,/g, ''));
