import React, {useCallback} from 'react';
import {Button, FormGroup, Input} from "reactstrap";
import _ from "underscore";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
	transactionSearchTextState,
	transactionTableCurrentPageNumberState,
	tempTransactionSearchTextState
} from "../../../../state/member-filters";

const TransactionsTableSearchFilter = () => {
	const setDebouncedFilterText = useSetRecoilState(transactionSearchTextState);
	const setTempFilterState = useSetRecoilState(tempTransactionSearchTextState);
	const tempFilterState = useRecoilValue(tempTransactionSearchTextState);
	const setTransactionTableCurrentPageNumberState = useSetRecoilState(transactionTableCurrentPageNumberState);

	const handleClear = () => {
		setDebouncedFilterText('');
		setTempFilterState('');
	};

	const handleChange = (event: any) => {
		setTempFilterState(event.target.value)
		debounce(event.target.value)
	};

	const debounce = useCallback(
		_.debounce((_searchVal: string) => {
			setTransactionTableCurrentPageNumberState(1);
			setDebouncedFilterText(_searchVal)
		}, 1000),
		[]
	);


	return (
		<div className="d-flex justify-content-end align-items-center">
			<FormGroup>
				<Input
					id="search"
					type="text"
					placeholder="Filter By 'Customer or Booking Reference'"
					aria-label="Search Input"
					value={tempFilterState}
					onInput={handleChange}
					className={'transactions-search-bar'} style={{backgroundColor: 'white'}}
				/>
			</FormGroup>
			<FormGroup>
				<Button type="button" onClick={handleClear} className="ml-2 btn-member-dark-gray">X</Button>
			</FormGroup>
		</div>
	);
}

export {TransactionsTableSearchFilter};