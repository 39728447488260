import {VectorMap} from 'react-jvectormap'
import React from 'react'
import './WorldMap.scss'
import {graphFilterName} from '../../../../../types/pulse'

const WorldMap = (props) => {
	const markerColor = '#0DFF9A'
	const regionStartColor = '#99B2FC'
	const regionEndColor = '#164FF8'
	const selectedMarkerFilter = props.mapData.getSelectedMarkerFilter()
	const selectedRegionFilter = props.mapData.getSelectedRegionFilter()
	const markerValues = props.mapData.getMarkerValues()

	function showToolTip(_, map, regionCode) {
		const content = props.mapData.getTooltipContent(regionCode)

		if (!content) {
			return false
		}

		map.html(content)
	}

	function showMarkerTip(_, map) {
		const regionCode = map.text()
		const content = props.mapData.getMarkerTooltipContent(regionCode)

		if (!content) {
			return false
		}

		map.html(content)
	}

	function clearTips() {
		const elements = document.getElementsByClassName('jvectormap-tip')
		Array.from(elements).forEach((el) => {
			el.style.display = 'none'
		})
	}

	return (
		<>
			<VectorMap
				backgroundColor="#FFFFFF"
				containerClassName="world-map"
				map={'world_mill'}
				markerStyle={{
					initial: {
						fill: markerColor,
						stroke: markerColor
					},
					hover: {
						fill: markerColor,
						stroke: markerColor,
						'fill-opacity': 0.85,
						'stroke-width': 1,
						'stroke-opacity': 0.85
					}
				}}
				regionStyle={{
					initial: {
						fill: '#BBBBBB',
						stroke: '#BBBBBB'
					}
				}}
				series={{
					regions: [{
						values: props.mapData.getRegionValues(),
						scale: [regionStartColor, regionEndColor]
					}],
					markers: [{
						values: markerValues.values,
						scale: [markerColor]
					},
						{
							attribute: 'r',
							scale: [8, 16],
							values: markerValues.values
						}]
				}}
				labels={{
					markers: {
						render(index) {
							return props.mapData.getMarkerLabel(index)
						}
					}
				}}
				zoomOnScroll={false}
				zoomButtons={true}
				onRegionTipShow={showToolTip}
				onMarkerTipShow={showMarkerTip}
				onMarkerOut={clearTips}
				onRegionOut={clearTips}
				markers={markerValues.countries}
			/>
			<div className="flex mt-4 space-x-4">
				{selectedMarkerFilter >= 0 && (
					<div className="flex items-center space-x-2">
						<div className="rounded-full w-4 h-4" style={{ backgroundColor: markerColor }}></div>
						<div className="text-sm">{graphFilterName[selectedMarkerFilter]}</div>
					</div>
				)}
				{selectedRegionFilter >= 0 && (
					<div className="flex items-center space-x-2">
						<div className="rounded h-4 w-12" style={{
							backgroundImage: `linear-gradient(to right, ${regionStartColor}, ${regionEndColor})`
						}}></div>
						<div className="text-sm">{graphFilterName[selectedRegionFilter]}</div>
					</div>
				)}
			</div>
		</>
	)
}

export default WorldMap
