import React from 'react'
import {formatCurrency} from '../../../utils/currency'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import classNames from 'classnames'

const OverviewChart = () => {
	const {getRefundProtectValueSummary, getProfitValueSummary, getBookingValueSummary} = usePulseApi()
	const grossValue = useApiValue(filterState, getBookingValueSummary, 0)
	const refundValue = useApiValue(filterState, getRefundProtectValueSummary, 0)
	const profitValue = useApiValue(filterState, getProfitValueSummary, 0)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const isLoading = grossValue.isLoading || refundValue.isLoading || profitValue.isLoading
	let refundWidth = grossValue.data > 0 ? (refundValue.data / grossValue.data) * 100 : 0
	let profitWidth = grossValue.data > 0 ? (profitValue.data / grossValue.data) * 100 : 0

	if (profitWidth > 0 && profitWidth < 5) {
		profitWidth = 5
	}

	if (refundWidth > 0 && refundWidth < 10) {
		refundWidth = 10
	}

	return (
		<div className={classNames('h-16 relative overflow-hidden', {'animate-pulse': isLoading})}>
			<div className="
				h-7 relative w-full
				bg-pulse-gray
				rounded-full"
			>
				{!isLoading && (
					<div className="
						absolute -bottom-7 right-0
						text-xs"
					>
						{formatCurrency(coreCurrency, grossValue.data)}
					</div>
				)}
			</div>
			{!isLoading && refundWidth > 0 && (
				<div
					className="
						h-7 -mt-7 relative
						bg-pulse-secondary-gray rounded-full"
					style={{'width': refundWidth + '%'}}
				>
					<div className="
						absolute -bottom-7 right-0 translate-x-2/4
						bg-white
						text-pulse-secondary-gray text-xs"
					>
						{formatCurrency(coreCurrency, refundValue.data)}
					</div>
				</div>
			)}
			{!isLoading && profitWidth > 0 && (
				<div
					className="
						h-7 -mt-7 relative
						bg-pulse-blue rounded-full"
					style={{'width': profitWidth + '%'}}
				>
					<div className="
						absolute -bottom-7 right-0 translate-x-2/4
						bg-white
						text-pulse-blue text-xs"
					>
						{formatCurrency(coreCurrency, profitValue.data)}
					</div>
				</div>
			)}
		</div>
	)
}

export default OverviewChart
