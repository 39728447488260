import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'

interface SecondaryValueCardProps {
	header: string,
	tooltipText: string,
	value: string,
	isLoading: boolean
}

const SecondaryValueCard = (props: SecondaryValueCardProps) => {
	const id = uuidv4()

	return (
		<div className="
			h-full min-h-32 px-7 py-5 relative
			bg-white
			border border-gray-200 rounded-lg"
		>
			<LoadingSpinner
				isLoading={props.isLoading}
				className="h-full w-full"
			>
				<>
					<div className="
						mb-1 h-10
						text-pulse-icon-gray text-sm
						lg:text-sm md:text-xs"
					>
						{props.header}
					</div>
					<div className="flex">
						<div className="flex-none mr-2">
							<div className="flex h-full items-center">
								<span className="
									mb-2
									text-2xl text-pulse-icon-gray
									material-symbols-outlined"
								>
									monitoring
								</span>
							</div>
						</div>
						<div>
							<div className="mb-1 text-2xl text-black">{props.value}</div>
							<Tooltip
								id={`${id}-popup`}
								tooltipTitle={props.header}
								tooltipText={props.tooltipText}
							/>
						</div>
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default SecondaryValueCard
