import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import DashboardSupport from './components/DashboardSupport'
import {useSetRecoilState} from 'recoil'
import {exportDataState} from '../../state/pulse'
import useExportTransactionData from './hooks/useExportTransactionData'
import TransactionTable from './components/TransactionTable'
import TransactionBatchModal from './components/Common/Transactions/TransactionBatchModal'
import TransactionOverviewModal from './components/Common/Transactions/TransactionOverviewModal'

const PulseTransactionsView = () => {
	const exportData = useExportTransactionData()
	const setExportDataState = useSetRecoilState(exportDataState)
	setExportDataState(exportData)

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<h2 className="
						mb-4
						font-semibold text-lg uppercase"
					>
						Transactions
					</h2>
					<div className="border-b border-bottom border-gray-200">
						<TransactionTable/>
						<TransactionOverviewModal/>
						<TransactionBatchModal/>
					</div>
				</section>
				<section className="mt-8">
					<div className="
						max-w-6xl p-4 w-full
						lg:mx-auto lg:px-10"
					>
						<DashboardSupport/>
					</div>
				</section>
			</>
		</DashboardPageLayout>
	)
}

export default PulseTransactionsView
