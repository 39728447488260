import {atom} from 'recoil'
import {NotificationMessage} from '../types'

export const isSidebarOpenState = atom<boolean>({
	key: 'isSidebarOpenState',
	default: false
})

export const dashboardTitleState = atom<string>({
	key: 'dashboardTitleState',
	default: ''
})

export const notificationMessageState = atom<NotificationMessage | null>({
	key: 'notificationMessageState',
	default: null
})

export const defaultRouteState = atom<string>({
	key: 'defaultRouteState',
	default: '/home'
})
