import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {selectedCoreCurrencyState} from '../../../../../state/pulse-filters'
import React, {useState} from 'react'
import moment from 'moment/moment'
import {dateFormat} from '../../../../../utils/date'
import {formatCurrency} from '../../../../../utils/currency'
import {Dialog} from '@headlessui/react'
import {selectedTransactionState, toastTextState, transactionTableUpdateTriggerState} from '../../../../../state/pulse'
import {loggedInUserState} from '../../../../../state/auth'
import {usePulseApi} from '../../../../../hooks/api/pulse'

const TransactionOverviewModal = () => {
	const {deleteTransactions} = usePulseApi()
	const [selectedTransaction, setSelectedTransaction] = useRecoilState(selectedTransactionState)
	const [cancelConfirmationState, setCancelConfirmationState] = useState(false)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const setToastText = useSetRecoilState(toastTextState)
	const resetSelectedTransaction = useResetRecoilState(selectedTransactionState)
	const setTransactionTableTrigger = useSetRecoilState(transactionTableUpdateTriggerState)
	const userName = useRecoilValue(loggedInUserState) ?? ''

	const handleModalClose = () => {
		setSelectedTransaction(null)
		setCancelConfirmationState(false)
	}

	const handleCancelConfirmation = () => {
		setCancelConfirmationState(true)
	}

	const handleCancel = async () => {
		const transactionId = selectedTransaction?.transactionId
		const transactionIds: number[] = []

		transactionIds.push(transactionId || 0)

		try {
			resetSelectedTransaction()
			await deleteTransactions({
				userName: userName,
				transactionIds: transactionIds,
			})
			setToastText(`Transaction #${transactionId} cancelled.`)
			setTransactionTableTrigger(curr => curr + 1)
		} catch (error: any) {
			setToastText(`Error occurred processing transaction #${transactionId}.`)
		} finally {
			handleModalClose()
		}
	}

	return (
		<>
			<Dialog
				open={(selectedTransaction?.transactionId || 0) > 0}
				onClose={handleModalClose}
				className="relative z-50"
			>
				<div
					className="
						fixed inset-0
						bg-black/30"
					aria-hidden="true"
				/>
				<div className="
					fixed flex inset-0 items-center justify-center p-4 w-screen"
				>
					{!cancelConfirmationState && (
						<Dialog.Panel className="
							relative max-w-sm min-w-[90%] mx-auto px-6 py-12
							bg-white rounded
							md:min-w-[50%]"
						>
							<div
								className="
									absolute right-4 top-4
									cursor-pointer"
								onClick={handleModalClose}
							>
								<span className="material-symbols-outlined">close</span>
							</div>
							<Dialog.Title
								className="
									mb-4 pb-4
									border-b border-pulse-icon-gray"
								style={{overflowWrap: 'anywhere'}}
								as="div"
							>
								<h3 className="font-medium">Customer Name</h3>
								{selectedTransaction?.customerName}
							</Dialog.Title>
							<Dialog.Description as="div">
								<div className="flex mb-8 space-x-3">
									<div className="flex-1">
										<h3 className="font-medium">Transaction ID</h3>
										{selectedTransaction?.transactionId}
									</div>
									<div className="flex-1">
										<h3 className="font-medium">Customer Fee</h3>
										{formatCurrency(coreCurrency, selectedTransaction?.transactionConsumerValue || 0, 0, 4)}
									</div>
								</div>
								<div className="flex mb-8 space-x-3">
									<div className="flex-1" style={{overflowWrap: 'anywhere'}}>
										<h3 className="font-medium">Booking Reference</h3>
										{selectedTransaction?.transactionReferenceId}
									</div>
									<div className="flex-1">
										<h3 className="font-medium">Refundable Value</h3>
										{formatCurrency(coreCurrency, selectedTransaction?.transactionValue || 0, 0, 4)}
									</div>
								</div>
								<div className="flex mb-8 space-x-3">
									<div className="flex-1">
										<h3 className="font-medium">Booking Date</h3>
										{moment(selectedTransaction?.transactionDate).format(dateFormat)}
									</div>
									<div className="flex-1">
										<h3 className="font-medium">Insured</h3>
										{selectedTransaction?.isInsured && (<>Yes</>)}
										{!selectedTransaction?.isInsured && (<>No</>)}
									</div>
								</div>
								<div className="flex mb-8 space-x-3">
									<div className="flex-1">
										<h3 className="font-medium">Event Date</h3>
										{moment(selectedTransaction?.transactionEndDate).format(dateFormat)}
									</div>
									<div className="flex-1">
										<h3 className="font-medium">Cancelled</h3>
										{selectedTransaction?.isCancelled && (<>Yes</>)}
										{!selectedTransaction?.isCancelled && (<>No</>)}
									</div>
								</div>
								<div className="flex mt-12 space-x-3">
									{!selectedTransaction?.isCancelled && (
										<div>
											<span
												className="
													inline-block px-3 py-[4px]
													bg-pulse-red rounded-full
													leading-5 text-sm tracking-[1px] uppercase
													cursor-pointer"
												onClick={handleCancelConfirmation}
											>
												Cancel Transaction
											</span>
										</div>
									)}
									<div>
										<span
											className="
												inline-block px-3 py-[4px]
												leading-5 text-sm tracking-[1px] uppercase
												border border-pulse-icon-gray rounded-full
												cursor-pointer"
											onClick={handleModalClose}
										>
											Close
										</span>
									</div>
								</div>
							</Dialog.Description>
						</Dialog.Panel>
					)}
					{cancelConfirmationState && (
						<Dialog.Panel
							className="
								max-w-sm min-w-[90%] mx-auto px-6 py-12 relative
								bg-white rounded
								md:min-w-[50%]"
						>
							<div
								className="
									absolute top-4 right-4
									cursor-pointer"
								onClick={handleModalClose}
							>
								<span className="material-symbols-outlined">close</span>
							</div>
							<Dialog.Title
								className="
									mb-4 pb-4
									border-b border-pulse-icon-gray"
								as="div"
							>
								<h3 className="font-medium">Cancel Transaction</h3>
							</Dialog.Title>
							<Dialog.Description as="div">
								<p>
									You are about to cancel the transaction #{selectedTransaction?.transactionId}. Are you sure?
								</p>
								<div className="flex mt-12 space-x-3">
									<div>
										<span
											className="
												inline-block px-3 py-[4px]
												bg-pulse-red rounded-full
												leading-5 text-sm tracking-[1px] uppercase
												cursor-pointer"
											onClick={handleCancel}
										>
											Cancel Transaction
										</span>
									</div>
									<div>
										<span
											className="
												inline-block px-3 py-[4px]
												border border-pulse-icon-gray rounded-full
												leading-5 text-sm tracking-[1px] uppercase
												cursor-pointer"
											onClick={handleModalClose}
										>
											Close
										</span>
									</div>
								</div>
							</Dialog.Description>
						</Dialog.Panel>
					)}
				</div>
			</Dialog>
		</>
	)
}

export default TransactionOverviewModal
