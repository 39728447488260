import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {defaultStatusData} from '../../../../types/integration'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'

const StatusCompletedSummary = memo(() => {
	const {getStatusSummary} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getStatusSummary, defaultStatusData)

	return (
		<ValueCard
			title={`Completed integrations`}
			value={data.completed}
			icon="icon-check-2"
			tooltip={'Total count of completed integrations'}
		/>
	)
})

export {StatusCompletedSummary}