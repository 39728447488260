export interface PulseFilter extends Record<string, any> {
	dateFrom: string | null
	dateTo: string | null
	memberId: number | null
	regionId: number | null
	userName: string | undefined
	coreCurrencyCode: string | null
	dateRange: string | null,
	graphFilters: graphFilterType[] | null
}

export enum dateRange {
	Month,
	Year,
	YearToDate,
	Custom
}

export interface PulseTableFilter extends PulseFilter {
	page: number
	pageSize: number
	sortColumn: string
	sortDirection: string
}

export interface PulseTransactionFilter extends PulseUserFilter {
	transactionIds: number[]
}

export interface PulseUserFilter extends Record<string, any> {
	userName: string | undefined
}

export enum graphFilterType {
	RefundProtectValue,
	YourRevenue,
	GrossBookingValue,
	RefundProtectVolume,
	GrossBookingVolume,
	RevenuePerDollar,
}

export const graphFilterName: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: 'Refund Protect Value',
	[graphFilterType.YourRevenue]: 'Your Revenue',
	[graphFilterType.GrossBookingValue]: 'Gross Booking Value',
	[graphFilterType.RefundProtectVolume]: 'Refund Protect Volume',
	[graphFilterType.GrossBookingVolume]: 'Gross Booking Volume',
	[graphFilterType.RevenuePerDollar]: 'Revenue Per $',
}

export const graphFilterColor: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: '#0084C4',
	[graphFilterType.YourRevenue]: '#23B77D',
	[graphFilterType.GrossBookingValue]: '#929292',
	[graphFilterType.RefundProtectVolume]: '#0084C4',
	[graphFilterType.GrossBookingVolume]: '#929292',
	[graphFilterType.RevenuePerDollar]: '#000000',
}

export const graphValueDemographics = [
	graphFilterType.RefundProtectValue,
	graphFilterType.YourRevenue,
	graphFilterType.GrossBookingValue
]

export const graphVolumeDemographics = [
	graphFilterType.RefundProtectVolume,
	graphFilterType.GrossBookingVolume
]

export const graphRevenue = [
	graphFilterType.RevenuePerDollar
]

export enum settingsPageType {
	Account
}

export enum batchAction {
	None,
	Cancel
}

export const batchActionName: Record<batchAction, string> = {
	[batchAction.None]: 'Actions',
	[batchAction.Cancel]: 'Cancel'
}
