import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import React from 'react'
import {Dialog} from '@headlessui/react'
import {
	selectedTransactionsState,
	toastTextState,
	transactionBatchActionState,
	transactionTableUpdateTriggerState
} from '../../../../../state/pulse'
import {loggedInUserState} from '../../../../../state/auth'
import {usePulseApi} from '../../../../../hooks/api/pulse'
import {batchAction} from '../../../../../types/pulse'

const TransactionBatchModal = () => {
	const {deleteTransactions} = usePulseApi()
	const selectedTransactions = useRecoilValue(selectedTransactionsState)
	const setToastText = useSetRecoilState(toastTextState)
	const resetSelectedTransactions = useResetRecoilState(selectedTransactionsState)
	const setTransactionTableTrigger = useSetRecoilState(transactionTableUpdateTriggerState)
	const [action, setAction] = useRecoilState(transactionBatchActionState)
	const userName = useRecoilValue(loggedInUserState) ?? ''

	const handleModalClose = () => {
		setAction(batchAction.None)
	}

	const handleCancel = async () => {
		const transactionIds: number[] = [...selectedTransactions]

		try {
			resetSelectedTransactions()

			await deleteTransactions({
				userName,
				transactionIds
			})

			setToastText(`Transactions #${transactionIds.join(', #')} cancelled.`)
			setTransactionTableTrigger(curr => curr + 1)
		} catch (error: any) {
			setToastText(`Error occurred processing transactions #${transactionIds.join(', #')}.`)
		} finally {
			handleModalClose()
		}
	}

	return (
		<>
			<Dialog
				open={selectedTransactions.length > 0 && action !== batchAction.None}
				onClose={handleModalClose}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
				<div className="
					fixed flex inset-0 items-center justify-center p-4 w-screen"
				>
					{action === batchAction.Cancel && (
						<Dialog.Panel className="
							px-6 py-12 max-w-sm min-w-[90%] mx-auto relative
							bg-white rounded
							md:min-w-[50%]"
						>
							<div
								className="
									absolute right-4 top-4
									cursor-pointer"
								onClick={handleModalClose}
							>
								<span className="material-symbols-outlined">close</span>
							</div>
							<Dialog.Title
								className="
									mb-4 pb-4
									border-b border-pulse-icon-gray"
								as="div"
							>
								<h3 className="font-medium">Cancel Transactions</h3>
							</Dialog.Title>
							<Dialog.Description as="div">
								<p>
									You are about to cancel {selectedTransactions.length} transactions. Are you sure?
								</p>
								<div className="flex mt-12 space-x-3">
									<div>
										<span
											className="
												inline-block px-3 py-[4px]
												bg-pulse-red rounded-full
												leading-5 text-sm tracking-[1px] uppercase
												cursor-pointer"
											onClick={handleCancel}
										>
											Cancel Transactions
										</span>
									</div>
									<div>
										<span
											className="
											inline-block px-3 py-[4px]
											border border-pulse-icon-gray rounded-full
											leading-5 text-sm tracking-[1px] uppercase
											cursor-pointer"
											onClick={handleModalClose}
										>
											Close
										</span>
									</div>
								</div>
							</Dialog.Description>
						</Dialog.Panel>
					)}
				</div>
			</Dialog>
		</>
	)
}

export default TransactionBatchModal
