import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotValueCard from './Common/TrustPilotValueCard/TrustPilotValueCard'

const RefundTrustPilotScoreSummary = () => {
	const {getAverageTrustPilotScore} = usePulseApi()
	const value = useApiValue(filterState, getAverageTrustPilotScore, 0)

	return (
		<TrustPilotValueCard
			value={`${value.data}`}
			header="TrustPilot Score"
			tooltipText="The Trust Pilot score of our Refund Protect offering."
			isLoading={value.isLoading}
		/>
	)
}

export default RefundTrustPilotScoreSummary
