import {atom, selector} from 'recoil'
import {Moment} from 'moment/moment'
import {dateFormat} from '../utils/date'
import moment from 'moment'
import {dateRange, graphFilterType} from '../types/pulse'

const keyPrefix = 'pulse'

export const dateFromState = atom<Moment>({
	key: `${keyPrefix}-dateFrom`,
	default: moment().startOf('year')
})

export const dateToState = atom<Moment>({
	key: `${keyPrefix}-dateTo`,
	default: moment().startOf('day')
})

export const dateMonthState = atom<Moment>({
	key: `${keyPrefix}-dateMonth`,
	default: moment().add(-1, 'month')
})

export const formattedDateFromState = selector<string>({
	key: `${keyPrefix}-formattedDateFrom`,
	get: ({get}) => get(dateFromState).format(dateFormat)
})

export const formattedDateToState = selector<string>({
	key: `${keyPrefix}-formattedDateTo`,
	get: ({get}) => get(dateToState).format(dateFormat)
})

export const selectedDateRangeState = selector<dateRange>({
	key: `${keyPrefix}-selectedDateRange`,
	get: ({get}) => {
		const fromDate = get(formattedDateFromState)
		const toDate = get(formattedDateToState)
		const beginningOfYear = moment().startOf('year').format(dateFormat)
		const today = moment().format(dateFormat)
		const beginningOfLastYear = moment()
			.add(-1, 'year')
			.startOf('year')
			.format(dateFormat)
		const endOfLastYear = moment()
			.add(-1, 'year')
			.endOf('year')
			.format(dateFormat)
		const beginningOfSelectedMonth = get(dateFromState)
			.startOf('month')
			.format(dateFormat)
		const endOfSelectedMonth = get(dateFromState)
			.endOf('month')
			.format(dateFormat)

		if (fromDate === beginningOfYear && toDate === today) {
			return dateRange.YearToDate
		}

		if (fromDate === beginningOfLastYear && toDate === endOfLastYear) {
			return dateRange.Year
		}

		if (fromDate === beginningOfSelectedMonth && toDate === endOfSelectedMonth) {
			return dateRange.Month
		}

		return dateRange.Custom
	}
})

export const selectedMemberIdState = atom<number | null>({
	key: `${keyPrefix}-selectedMemberId`,
	default: null
})

export const selectedRegionIdState = atom<number | null>({
	key: `${keyPrefix}-selectedRegionId`,
	default: null
})

export const selectedCoreCurrencyState = atom<string>({
	key: `${keyPrefix}-selectedCoreCurrency`,
	default: 'USD'
})

export const selectedGraphFiltersState = atom<graphFilterType[]>({
	key: `${keyPrefix}-selectedGraphFilters`,
	default: [graphFilterType.RefundProtectValue]
})
