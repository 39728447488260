import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import DashboardSupport from './components/DashboardSupport'
import {useSetRecoilState} from 'recoil'
import {exportDataState} from '../../state/pulse'
import RevenueTrendsGraph from './components/RevenueTrendsGraph'
import useExportRevenueTrendsData from './hooks/useExportRevenueTrendsData'

const PulseRevenueTrendsView = () => {
	const exportData = useExportRevenueTrendsData()
	const setExportDataState = useSetRecoilState(exportDataState)
	setExportDataState(exportData)

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl px-4 w-full
					lg:mx-auto lg:px-10"
				>
					<div className="mb-8">
						<RevenueTrendsGraph/>
					</div>
					<div>
						<DashboardSupport/>
					</div>
				</section>
			</>
		</DashboardPageLayout>
	)
}

export default PulseRevenueTrendsView
